import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Banner from '../images/Banner';

const Header = () => {
  const location = useLocation().pathname;
  const { t } = useTranslation();
  const PAGE_WHITELIST = ['/'];

  if (!PAGE_WHITELIST.includes(location)) return null;

  return (
    <header className="flex flex-col xl:flex-row md:mb-9 xl:items-end md:ml-3">
      <Banner className="w-9/10 sm:w-fit md:h-10 xl:w-auto mb-2 md:mr-6 fill-black dark:fill-white transition duration-500" />
      <div className="flex flex-col">
        <h1 className="h-full content-center text-xl md:text-2xl font-bold whitespace-pre-line -mb-2 hidden xl:block">
          {t('header.studio')}
        </h1>
        <h2 className="h-full content-center text-xl md:text-2xl font-bold whitespace-pre-line">
          {t('header.baseline')}
        </h2>
      </div>
    </header>
  );
};

export default Header;
