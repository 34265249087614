/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const letteringCardsEn = [
  {
    title: 'Fall in Love \nwith Spring',
    type: 'Manga lettering',
    subtitle: 'Manga lettering, one-shot.\nBruno DURAND — Chattochatto.',
    imgSrc: '/images/cover/filws.webp',
    imgFullSrc: '/images/cover/filws.webp',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Aoki est amoureux de Hashimoto, sa voisine de classe. Mais un jour, en lui empruntant sa
          gomme, il découvre avec horreur que l'élue de son cœur en aime un autre : le beau Ida, qui
          est assis juste devant lui. L'adolescent est tellement déçu qu'il ne réalise pas qu'il a
          fait tomber la gomme de sa camarade et que son supposé rival en amour est en train de la
          ramasser… C'est le début d'un énorme malentendu qui pourrait aboutir à la plus belle des
          histoires d'amour !
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-4">
          <span className="italic">Love Mix-Up</span>
          , c'est une romance moderne et bourrée d'humour
          qui, grâce à ses personnages aussi bienveillants que maladroits, ne manquera pas de faire
          chavirer vos cœurs !
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Maison d'édition :</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.akata.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Akata Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Scénario :</span>
              {' '}
              Wataru Hinekure
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Dessin :</span>
              {' '}
              Aruko
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction :</span>
              {' '}
              Aline Kukor
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Adaptation graphique :</span>
              {' '}
              Kelly Cotaquispe (NO HIT
              Studio)
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Couverture :</span>
              {' '}
              Clémence Aresu
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'dragon-metropolis',
    title: 'Dragon \nMetropolis',
    type: 'Manga lettering',
    subtitle: 'Manga lettering, volume 5.\nBruno DURAND — Chattochatto.',
    imgSrc: '/images/cover/dm.webp',
    imgFullSrc: '/images/cover/dm.webp',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Dimir's plan takes shape under the terrified gaze of Artemis and
          Zhengyi. To prevent the collapse of Dragon Dynasty,
          the royal family intervenes and uncovers the truth behind
          what originally appeared to be a fratricidal war.
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French publisher:</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.chattochatto.com/"
                target="_blank"
                rel="noreferrer"
              >
                Chattochatto
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Scenario:</span>
              {' '}
              Barz JR.
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Art:</span>
              {' '}
              Barz JR.
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French Translation:</span>
              {' '}
              Daphne Huang
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettering:</span>
              {' '}
              Bruno Durand (NO HIT
              Studio)
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Cover Design:</span>
              {' '}
              Tom "spAde" Bertrand
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'self',
    title: 'Self___',
    type: 'Manga lettering',
    subtitle: 'Manga lettering, one-shot.\nBruno DURAND — Chattochatto.',
    imgSrc: '/images/cover/self.webp',
    imgFullSrc: '/images/cover/self.webp',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          How do you cope with negative thoughts?
          When, despite success, you feel incapable and inadequate?
          How do you coexist with yourself when pragmatism clashes with your childlike spirit?
          Yanai tells us in this short story how self-acceptance can help you escape
          the downward spiral of depression.
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French publisher:</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.chattochatto.com/"
                target="_blank"
                rel="noreferrer"
              >
                Chattochatto
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Scenario:</span>
              {' '}
              Yanai
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Art:</span>
              {' '}
              Yanai
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French Translation:</span>
              {' '}
              Emilie Gervaise
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettering:</span>
              {' '}
              Bruno Durand (NO HIT
              Studio)
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Cover Design:</span>
              {' '}
              Bruno Durand (NO HIT
              Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'love-mix-up',
    title: 'Love Mix-Up',
    type: 'Manga lettering',
    subtitle: 'Manga lettering, volume 1 to 5.\nKelly COTAQUISPE — Akata Éditions.',
    imgSrc: '/images/cover/love-mu.webp',
    imgFullSrc: '/images/cover/love-mu.webp',
    imgCredits: '',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Aoki is in love with Hashimoto, his classmate. But one day, while borrowing his eraser, he
          discovers with horror that the one he loves is in love with another: the handsome Ida, who
          is sitting right in front of him. The teenager is so disappointed that he doesn't realize
          that he has dropped his classmate's eraser and that his supposed rival in love is picking
          it up... This is the beginning of a huge misunderstanding that could lead to the most
          beautiful love story ever!
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-4">
          <span className="italic">Love Mix-Up</span>
          {' '}
          is a modern and humorous romance which, thanks
          to its characters who are as benevolent as they are awkward, is sure to make your heart
          skip a beat!
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French publisher:</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.akata.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Akata Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Scenario:</span>
              {' '}
              Wataru Hinekure
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Art:</span>
              {' '}
              Aruko
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French Translation:</span>
              {' '}
              Aline Kukor
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettering:</span>
              {' '}
              Kelly Cotaquispe (NO HIT Studio)
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Cover Design:</span>
              {' '}
              Clémence Aresu
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'scums-wish',
    title: "Scum's Wish",
    type: 'Manga lettering',
    subtitle: 'Manga lettering, volume 1 to 3.\nBruno DURAND — Noeve Grafx.',
    imgSrc: '/images/cover/scums.webp',
    imgFullSrc: '/images/cover/scums.webp',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-4">
          <span className="font-bold">Mugi</span>
          {' '}
          and
          {' '}
          <span className="font-bold">Hanabi</span>
          {' '}
          are
          the ideal couple: beautiful, sweet and in love, the whole school idolizes them. But their
          relationship is based on a mutual secret: each one is only a substitute for the other.
          Consumed by impossible loves, each one consoles himself in the arms of the other and tries
          to move forward... But is lying to each other really moving forward?
        </p>
        <div className="lg:grid lg:grid-cols-[47%_53%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French publisher:</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://noeve.com/"
                target="_blank"
                rel="noreferrer"
              >
                Noeve Grafx
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Author:</span>
              {' '}
              Yokoyari Mengo
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Editorial direction:</span>
              {' '}
              Bertrand Brillois
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Conception and graphic supervision:</span>
              {' '}
              Kevin Druelle
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French Translation:</span>
              {' '}
              Aurélie Brun
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Proofreading:</span>
              {' '}
              Anaïs Fourny
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettering:</span>
              {' '}
              Bruno Durand (NO HIT Studio)
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Internal finalization:</span>
              {' '}
              Emma Poirrier
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'weeping-moon',
    title: 'Weeping\nMoon',
    type: 'Manga lettering',
    subtitle: 'Manga lettering.\nBruno DURAND — Tsubomi Éditions.',
    imgSrc: '/images/cover/wm.webp',
    imgFullSrc: '/images/cover/wm.webp',
    imgCredits: '',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Lost in a collapsing apocalyptic world, Moe-Moe, a young girl with a stubborn
          look, shares her daily life with a monstrous being covered in bandages.
          Despite his austere appearance and dark secrets, he shows infinite
          benevolence towards his young human friend whom he considers his daughter...
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Publisher:</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.tsubomi.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Tsubomi Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Author:</span>
              {' '}
              Matsunoki Seven
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Art:</span>
              {' '}
              Matsunoki Seven
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">English Translation:</span>
              {' '}
              Jessica Gunawan
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French Translation:</span>
              {' '}
              Marylou Leclerc
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettering:</span>
              {' '}
              Bruno Durand (NO HIT Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'teke-teke',
    title: 'As Long as\nthe Demons Remain',
    type: 'Manga lettering',
    subtitle: 'Manga lettering.\nBruno DURAND — Tsubomi Éditions.',
    imgSrc: '/images/cover/teke.webp',
    imgFullSrc: '/images/cover/teke.webp',
    imgCredits: '',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Since a terrible accident, the discreet Hotaru Ashinozuka is watched day and
          night by evil forces. Determined to ignore these abominations, she continues
          her schooling with the hope of living better days.
          However, her meeting with Azami, a new student with a cold and dark beauty,
          will push her to assume her frightening secret.
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Publisher:</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.tsubomi.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Tsubomi Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Author:</span>
              {' '}
              Minowa Bunko
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Art:</span>
              {' '}
              Minowa Bunko
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">English Translation:</span>
              {' '}
              Mikey Nguyen
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French Translation:</span>
              {' '}
              Marina Bonzi
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettering:</span>
              {' '}
              Bruno Durand (NO HIT Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'meek',
    title: 'Meek\nthe Mule',
    type: 'Manga lettering',
    subtitle: 'Manga lettering.\nBruno DURAND — Tsubomi Éditions.',
    imgSrc: '/images/cover/meek.webp',
    imgFullSrc: '/images/cover/meek.webp',
    imgCredits: '',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Following in his father's footsteps, Meek, a young carrier travels through mysterious
          and terrible lands to deliver his cargo. Only one rule: never look at the contents...
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Publisher:</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.tsubomi.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Tsubomi Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Author:</span>
              {' '}
              Shun Miyake
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Art:</span>
              {' '}
              Shun Miyake
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">English Translation:</span>
              {' '}
              Jet Kint
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French Translation:</span>
              {' '}
              Myriam Ayachi
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettering:</span>
              {' '}
              Bruno Durand (NO HIT Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'telomere',
    title: 'Telomere',
    type: 'Manga lettering',
    subtitle: 'Manga lettering.\nBruno DURAND — Tsubomi Éditions.',
    imgSrc: '/images/cover/telo.webp',
    imgFullSrc: '/images/cover/telo.webp',
    imgCredits: '',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          A violent virus has decimated the entire world's population,
          20 years later, billions of zombies roam the earth.
          Kou and his twin Yuki travel through a devastated Japan and lead their own
          expeditions. They will have to face many dangers and a brand new threat...
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Publisher:</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.tsubomi.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Tsubomi Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Author:</span>
              {' '}
              Kakio Tsurukawa
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Art:</span>
              {' '}
              Kakio Tsurukawa
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">English Translation:</span>
              {' '}
              Jessica Gunawan
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French Translation:</span>
              {' '}
              Kevin Stocker
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettering:</span>
              {' '}
              Bruno Durand (NO HIT Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'eternal-blue',
    title: 'Eternal Blue',
    type: 'Manga lettering',
    subtitle: 'Lettrage du manga.\nBruno DURAND — Tsubomi Éditions.',
    imgSrc: '/images/cover/eb.webp',
    imgFullSrc: '/images/cover/eb.webp',
    imgCredits: '',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Ao, the demanding and sarcastic boss, wastes no time in putting Mikio,
          the new employee of the Royal Library, to the test.
          Both guardians of knowledge and protectors of the readers' sanity,
          they dedicate themselves together to a crazy fight against
          formidable enemies: the Evil Books.
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Publisher:</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.tsubomi.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Tsubomi Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Author:</span>
              {' '}
              Mimishiki
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Art:</span>
              {' '}
              Mimishiki
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">English Translation:</span>
              {' '}
              Jessica Gunawan
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French Translation:</span>
              {' '}
              Marina Bonzi
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettering:</span>
              {' '}
              Bruno Durand (NO HIT Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'vampeerz',
    title: 'Vampeerz',
    type: 'Manga lettering',
    subtitle: 'Manga lettering, volume 1.\nKelly COTAQUISPE — Akata Éditions.',
    imgAlt: 'This is an alt',
    imgSrc: '/images/cover/vamp.webp',
    imgFullSrc: '/images/cover/vamp.webp',
    imgCredits: '',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          On the day of her grandmother's funeral, Ichika falls in love at first
          sight with a young girl she knows nothing about. The mysterious girl,
          Aria, turns out to be a thousand-year-old vampire. Still, the teenager
          can't forget the words of her foremother, "The perfect person is the one
          who will steal your heart." For a human who had never felt such love before,
          is it wise to succumb in the arms of a vampire? And what are her true intentions?
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-4">
          In
          {' '}
          <span className="italic">Vampeerz</span>
          , a series mixing cleverly romance, action and horror, follow the
          daily life of a schoolgirl who falls in love with a vampire with a death wish...
          With a smooth narration and just the right amount of casualness,
          AKILI offers a particularly effective series.
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French publisher:</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.akata.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Akata Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Author:</span>
              {' '}
              AKILI
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Cover Design:</span>
              {' '}
              Clémence Aresu
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">French Translation:</span>
              {' '}
              Vincent Marcantognini
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettering:</span>
              {' '}
              Kelly Cotaquispe (NO HIT Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'berserk-of-gluttony',
    title: 'Berserk of\n Gluttony',
    type: 'Manga lettering',
    subtitle: 'Manga lettering, volume 1 & 2.\nBruno DURAND — Mahô Éditions.',
    imgAlt: 'Berserk of Gluttony volume 01 cover',
    imgSrc: '/images/cover/bog.webp',
    imgFullSrc: '/images/cover/bog.webp',
    imgCredits: (
      <div>
        <p className="text-md dark:text-white text-black text-justify">
          <span className="font-bold">French publisher:</span>
          {' '}
          <a
            className="text-coolerOrange"
            href="https://www.maho-editions.com/"
            target="_blank"
            rel="noreferrer"
          >
            Mahô Éditions
          </a>
        </p>
        <p className="text-md dark:text-white text-black text-justify pt-2">
          <span className="font-bold">Scenario:</span>
          {' '}
          Ichika ISSHIKI
        </p>
        <p className="text-md dark:text-white text-black text-justify">
          <span className="font-bold">Art:</span>
          {' '}
          Daisuke TAKINO
        </p>
        <p className="text-md dark:text-white text-black text-justify">
          <span className="font-bold">Original design:</span>
          {' '}
          <span className="italic">fame</span>
        </p>
        <p className="text-md dark:text-white text-black text-justify pt-2">
          <span className="font-bold">Editorial direction:</span>
          {' '}
          Aurélien Martinez
        </p>
        <p className="text-md dark:text-white text-black text-justify">
          <span className="font-bold">Marketing team:</span>
          {' '}
          <br />
          Jérémy Touzet, Caroline Delmon
        </p>
        <p className="text-md dark:text-white text-black text-justify pt-2">
          <span className="font-bold">Translation:</span>
          {' '}
          Yukio Reuter
        </p>
        <p className="text-md dark:text-white text-black text-justify">
          <span className="font-bold">Lettering:</span>
          {' '}
          <br />
          Bruno Durand (NO HIT Studio)
        </p>
        <p className="text-md dark:text-white text-black text-justify">
          <span className="font-bold">Composition and layout:</span>
          {' '}
          Ludovic Fily
        </p>
      </div>
    ),
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <h1 className="text-lg dark:text-white text-black text-justify pb-4 font-bold">
          Behind the scenes of lettering and retouching of the manga Berserk of Gluttony
          volume 01, available at
          {' '}
          <a
            className="text-coolerOrange"
            href="https://www.maho-editions.com/"
            target="_blank"
            rel="noreferrer"
          >
            Mahô Éditions
          </a>
          .
        </h1>
        <div className="lg:grid lg:grid-cols-[60%_40%] lg:gap-8 pb-2">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify pb-2 md:pr-4">
              It was decided with the french editor to adapt all the onomatopoeia in
              French (and not to subtitles as it is quite commonly done) for a better
              immersion of the reader. It was therefore necessary to touch-up a good part
              of the pages (redraw the parts of the drawing that are overwritten by
              Japanese SFX).
            </p>
            <h3 className="text-lg dark:text-white text-black text-justify pt-2 font-bold">
              Touch-up :
            </h3>
            <p className="text-md dark:text-white text-black text-justify pb-2 md:pr-4">
              One of the first pages you will see in this volume is the following image,
              which is the first page of volume 01 (excluding the summary and side pages).
              You can see that on this page the ballons are above the SFX.
              The first step is to isolate the ballons from the rest of the background
              in order to be able to redraw more easily the SFX and then in a second step,
              to add French ones.
            </p>
            <p className="text-md dark:text-white text-black text-justify pb-2 md:pr-4">
              Also, we notice that the balloons have a glow effect that must be taken
              into consideration.
            </p>
            <p className="text-md dark:text-white text-black text-justify pb-2 md:pr-4">
              Step 1: Isolate the balloons and put back the glow effect.
              It may seem difficult here, but Photoshop has a very handy
              option for this, selection by color range.
            </p>
            <img
              src="https://pbs.twimg.com/media/FACcN5MVkAEfEVl?format=jpg&name=small"
              className="flex mx-auto pb-2 w-64"
              alt=""
            />
            <p className="text-sm italic text-center">Color range selection on Photoshop</p>
          </div>
          <div className="md:pr-2">
            <img
              src="https://pbs.twimg.com/media/FACTxWMUUAQpHF5?format=jpg&name=4096x4096"
              className="pb-2"
              alt=""
            />
            <p className="text-sm pb-4 italic">Berserk of Gluttony, volume 01, page 03.</p>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-[60%_40%] lg:gap-8">
          <div className="lg:row-start-1 lg:col-start-2 relative">
            <img
              src="https://pbs.twimg.com/media/FACYIuzVQAAomn-?format=jpg&name=4096x4096"
              className="pb-2 md:pr-4"
              alt=""
            />
            <p className="text-sm pb-4 italic">Isolated balloons with adding external glow.</p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify pb-2">
              All the black pixels were automatically selected around them and after a
              bit of cleanup, it was just a matter of copy and paste, applying a glow
              effect to the layers and putting it all on top of the file. Here is the
              result once everything is isolated (see next image).
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              Then, it only remains to redraw what is underneath
              (more or less roughly since some areas will be covered
              by the French SFX) and add the new SFX.
              So here is the cleaned and finalized page:
            </p>
            <div className="lg:grid lg:grid-cols-2 lg:gap-1 py-2">
              <div className="lg:row-start-1 lg:col-start-1 relative">
                <img
                  src="https://pbs.twimg.com/media/FACYa8zVkAQOK4p?format=jpg&name=4096x4096"
                  alt=""
                />
                <p className="text-sm pt-2 pb-2 italic">Cleaned page</p>
              </div>
              <div>
                <img
                  src="https://pbs.twimg.com/media/FACYa-eVgAMimZP?format=jpg&name=4096x4096"
                  alt=""
                />
                <p className="text-sm pt-2 italic">Addition of french SFX</p>
              </div>
            </div>
          </div>
        </div>
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Many other pages have been treated in the same way as the one above,
          but a letterer's job is not an illustrator's job and some images can be
          complex to redraw and that's where you have to be clever.
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-2">
          How to adapt the SFX as faithfully as possible without having to redraw a whole panel?
          We have seen just before a completely cleaned up page, but when it is too complex or
          time consuming, the reconstructions are made only on the parts visible to the reader,
          and it gives this result:
        </p>
        <div className="lg:grid lg:grid-cols-3 lg:gap-1 py-2">
          <div>
            <img src="https://pbs.twimg.com/media/FACaMj6VIAUdIR6?format=jpg&name=large" alt="" />
            <p className="text-sm pt-2 pb-2 italic">1. Originale page</p>
          </div>
          <div>
            <img src="https://pbs.twimg.com/media/FACaSDXVQAweX3G?format=jpg&name=large" alt="" />
            <p className="text-sm pt-2 pb-2 italic">2. Quick removal of japanese SFX</p>
          </div>
          <div>
            <img src="https://pbs.twimg.com/media/FACaUgZVEAgdueU?format=jpg&name=large" alt="" />
            <p className="text-sm pt-2 pb-2 pl-2 italic">3. Adding french SFX</p>
          </div>
        </div>
        <h3 className="text-lg dark:text-white text-black text-justify pt-2 pb-2 font-bold">
          Balloons and fonts:
        </h3>
        <p className="text-md dark:text-white text-black text-justify pb-2">
          When defining the typographic guidelines with the editor, we chose to focus on
          the readability with a selection of fonts that readers are used to read and that
          are therefore comfortable for the eyes. We find naturally the traditional Komika Jim
          for balloons, cases, thoughts... that you could see on many French titles like
          Jujutsu Kaisen, My Hero Academia or Kingdom.
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Here an example:
        </p>
        <img
          src="https://pbs.twimg.com/media/FACawrYUcAM3Lfe?format=jpg&name=small"
          className="flex mx-auto pb-2 w-80"
          alt=""
        />
        <p className="text-md dark:text-white text-black text-justify pb-2">
          The other important font is the one used when Greed speaks. His voice seems more
          hoarse in the Japanese version, so we chose a font that you know if you have read
          {' '}
          <a
            className="text-coolerOrange"
            href="https://twitter.com/Blackfury__"
            target="_blank"
            rel="noreferrer"
          >
            Blackfury
          </a>
          {' '}
          or some shonen or seinen, CC Meanwhile.
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Unfortunately, no example here with Berserk of Gluttony to avoid any spoilers!
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-2">
          That's basically it for the main fonts. There are a few other situational ones,
          but that's pretty rare in the whole book.
        </p>
        <p className="text-md dark:text-white text-black text-justify pt-2">
          Thanks for reading the article!
        </p>
        <p className="text-md dark:text-white text-black text-justify italic">
          Article written by Bruno DURAND on September 24, 2021.
          {' '}
          <a
            className="text-coolerOrange"
            href="https://twitter.com/Blackfury__"
            target="_blank"
            rel="noreferrer"
          >
            View the original Twitter thread
          </a>
        </p>
        <p className="text-md dark:text-white text-black text-justify italic">
          Last modified on December 21, 2022.
        </p>
      </div>
    ),
  },
  {
    title: 'Black Fury\n Adventures',
    type: 'Manga lettering',
    subtitle: 'Manga lettering, volume 1 & 2.\nBruno DURAND — Mahô Éditions.',
    imgAlt: 'This is an alt',
    imgSrc: '/images/cover/bf.webp',
    imgFullSrc: '/images/cover/bf.webp',
    imgCredits: 'Crédits: ©Ella DUBOL',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <div className="text-base max-w-prose mx-auto lg:max-w-none">
          <p className="text-lg dark:text-white text-black ">
            Bucaneers are the shipmates of the big madness. Desire me grog, ye heavy-hearted pin!
            The shark marks with horror, view the galley.
          </p>
        </div>
        <div className="mt-5 prose prose-indigo dark:text-white text-black mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
          <p>
            Divided, melted pudding is best flavored with salted hollandaise sauce. With blueberries
            drink peppermint tea. Blood oranges can be tossed with raw pumpkin seeds, also try
            seasoning the platter with bourbon.
          </p>

          <p className="mt-5 mb-5">
            This anomaly has only been yearned by a spheroid sun. Why does the sonic shower harvest?
            Sensors meet with stigma at the fantastic habitat accelerativemake it so! Creature of a
            harmless starlight travel, discover the sensor.
          </p>
          <p className="mt-5 mb-5">
            Dictum magnis risus phasellus vitae quam morbi. Quis lorem lorem arcu, metus, egestas
            netus cursus. In.
          </p>
          <p className="mt-5 mb-5">
            Rhoncus nisl, libero egestas diam fermentum dui. At quis tincidunt vel ultricies.
            Vulputate aliquet velit faucibus semper. Pellentesque in venenatis vestibulum
            consectetur nibh id. In id ut tempus egestas. Enim sit aliquam nec, a. Morbi enim
            fermentum lacus in. Viverra.
          </p>
          <p className="mt-5 mb-5">
            To the instant shrimps add chicken lard, melon, vinegar and chilled ground beef. With
            spinach drink plain vinegar. Try roasting pork shoulder soup garnished with gold
            tequila. Try sliceing nachos ricotta mixed with rice vinegar. Try enameling the red wine
            mackerels with aged anchovy essence and triple sec, roasted.
          </p>

          <h3>How we helped</h3>
          <p>
            Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in
            volutpat, diam. Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet
            sem est phasellus eu proin massa, lectus. Diam rutrum posuere donec ultricies non morbi.
            Mi a platea auctor mi.
          </p>
          <p>
            Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur
            neque tristique pellentesque. Blandit amet, sed aenean erat arcu morbi.
          </p>
        </div>
      </div>
    ),
  },
  {
    title: 'Contes\nmerveilleux du printemps',
    type: 'Manga lettering',
    subtitle: 'Manga lettering, one-shot\nBruno DURAND — Mahô Éditions.',
    imgAlt: 'This is an alt',
    imgSrc: '/images/cover/cmp.webp',
    imgFullSrc: '/images/cover/cmp.webp',
    imgCredits: 'Crédits: ©Élie COPTAIRE',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <div className="text-base max-w-prose mx-auto lg:max-w-none">
          <p className="text-lg dark:text-white text-black ">
            Bucaneers are the shipmates of the big madness. Desire me grog, ye heavy-hearted pin!
            The shark marks with horror, view the galley.
          </p>
        </div>
        <div className="mt-5 prose prose-indigo dark:text-white text-black mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
          <p>
            Divided, melted pudding is best flavored with salted hollandaise sauce. With blueberries
            drink peppermint tea. Blood oranges can be tossed with raw pumpkin seeds, also try
            seasoning the platter with bourbon.
          </p>

          <p className="mt-5 mb-5">
            This anomaly has only been yearned by a spheroid sun. Why does the sonic shower harvest?
            Sensors meet with stigma at the fantastic habitat accelerativemake it so! Creature of a
            harmless starlight travel, discover the sensor.
          </p>
          <p className="mt-5 mb-5">
            Dictum magnis risus phasellus vitae quam morbi. Quis lorem lorem arcu, metus, egestas
            netus cursus. In.
          </p>
          <p className="mt-5 mb-5">
            Rhoncus nisl, libero egestas diam fermentum dui. At quis tincidunt vel ultricies.
            Vulputate aliquet velit faucibus semper. Pellentesque in venenatis vestibulum
            consectetur nibh id. In id ut tempus egestas. Enim sit aliquam nec, a. Morbi enim
            fermentum lacus in. Viverra.
          </p>
          <p className="mt-5 mb-5">
            To the instant shrimps add chicken lard, melon, vinegar and chilled ground beef. With
            spinach drink plain vinegar. Try roasting pork shoulder soup garnished with gold
            tequila. Try sliceing nachos ricotta mixed with rice vinegar. Try enameling the red wine
            mackerels with aged anchovy essence and triple sec, roasted.
          </p>

          <h3>How we helped</h3>
          <p>
            Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in
            volutpat, diam. Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet
            sem est phasellus eu proin massa, lectus. Diam rutrum posuere donec ultricies non morbi.
            Mi a platea auctor mi.
          </p>
          <p>
            Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur
            neque tristique pellentesque. Blandit amet, sed aenean erat arcu morbi.
          </p>
        </div>
      </div>
    ),
  },
  {
    title: 'Zombie\n From Now On !',
    type: 'Manga lettering',
    subtitle: 'Manga lettering, volume 1 & 2.\nBruno DURAND — Chattochatto.',
    imgAlt: 'This is an alt',
    imgSrc: '/images/cover/zfno.webp',
    imgFullSrc: '/images/cover/zfno.webp',
    imgCredits: 'Crédits: ©Théo TOUALETTE',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <div className="text-base max-w-prose mx-auto lg:max-w-none">
          <p className="text-lg dark:text-white text-black ">
            Bucaneers are the shipmates of the big madness. Desire me grog, ye heavy-hearted pin!
            The shark marks with horror, view the galley.
          </p>
        </div>
        <div className="mt-5 prose prose-indigo dark:text-white text-black mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
          <p>
            Divided, melted pudding is best flavored with salted hollandaise sauce. With blueberries
            drink peppermint tea. Blood oranges can be tossed with raw pumpkin seeds, also try
            seasoning the platter with bourbon.
          </p>

          <p className="mt-5 mb-5">
            This anomaly has only been yearned by a spheroid sun. Why does the sonic shower harvest?
            Sensors meet with stigma at the fantastic habitat accelerativemake it so! Creature of a
            harmless starlight travel, discover the sensor.
          </p>
          <p className="mt-5 mb-5">
            Dictum magnis risus phasellus vitae quam morbi. Quis lorem lorem arcu, metus, egestas
            netus cursus. In.
          </p>
          <p className="mt-5 mb-5">
            Rhoncus nisl, libero egestas diam fermentum dui. At quis tincidunt vel ultricies.
            Vulputate aliquet velit faucibus semper. Pellentesque in venenatis vestibulum
            consectetur nibh id. In id ut tempus egestas. Enim sit aliquam nec, a. Morbi enim
            fermentum lacus in. Viverra.
          </p>
          <p className="mt-5 mb-5">
            To the instant shrimps add chicken lard, melon, vinegar and chilled ground beef. With
            spinach drink plain vinegar. Try roasting pork shoulder soup garnished with gold
            tequila. Try sliceing nachos ricotta mixed with rice vinegar. Try enameling the red wine
            mackerels with aged anchovy essence and triple sec, roasted.
          </p>

          <h3>How we helped</h3>
          <p>
            Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in
            volutpat, diam. Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet
            sem est phasellus eu proin massa, lectus. Diam rutrum posuere donec ultricies non morbi.
            Mi a platea auctor mi.
          </p>
          <p>
            Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur
            neque tristique pellentesque. Blandit amet, sed aenean erat arcu morbi.
          </p>
        </div>
      </div>
    ),
  },
];

export default letteringCardsEn;
