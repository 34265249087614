import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Back from '../images/Back';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="h-full w-full flex items-center justify-center flex-col md:pr-10 xl:pr-20">
      <h1 className="text-9xl font-extrabold">{t('notFound.404')}</h1>
      <Link to="/" className="flex items-center mt-2">
        <Back className="w-6 md:w-8 md:pt-2 mr-4 fill-black dark:fill-white transition duration-500" />
        <h3 className="text-2xl md:text-4xl font-extrabold uppercase">
          {t('notFound.pageNotFound')}
        </h3>
      </Link>
    </div>
  );
};

export default NotFound;
