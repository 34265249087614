import React from 'react';
import PropTypes from 'prop-types';

const Banner = ({
  className,
}) => (
  <svg id="Calque_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 537.28 61.37 " className={className}>
    <g id="Calque_1-2">
      <path className="cls-1" d="m201.63,2.64v56.09h-18.91v-20.67h-17.47v20.67h-18.91V2.64h18.91v19.87h17.47V2.64h18.91Z" />
      <path className="cls-1" d="m210.77,2.64h18.91v56.09h-18.91V2.64Z" />
      <path className="cls-1" d="m250.99,17.31h-16.42V2.64h51.76v14.66h-16.43v41.42h-18.91V17.31Z" />
      <path className="cls-1" d="m55.28,2.64v56.09h-15.54l-21.31-25.48v25.48H0V2.64h15.54l21.31,25.48V2.64h18.43Z" />
      <path className="cls-1" d="m93.23,0c-17.52,0-31.72,13.74-31.72,30.69,0,6.36,2,12.26,5.42,17.16l-2.94,10.99,11.11-2.98c5.14,3.47,11.39,5.52,18.14,5.52,17.52,0,31.72-13.74,31.72-30.69S110.75,0,93.23,0Zm.34,45.31c-8.39,0-15.18-6.58-15.18-14.69s6.8-14.69,15.18-14.69,15.18,6.58,15.18,14.69-6.8,14.69-15.18,14.69Z" />
      <path className="cls-1" d="m312.03,40.91h8.42c.15,6.99,4.21,11.35,13.53,11.35,7.67,0,12.78-2.78,12.78-8.57,0-6.54-3.68-8.35-14.44-10.15-12.56-2.18-19.02-5.86-19.02-15.26s7.59-15.64,20.15-15.64,19.92,6.39,20.07,16.01h-8.35c-.23-6.54-4.96-9.7-12.03-9.7s-11.35,3.23-11.35,8.2c0,6.09,4.14,7.29,14.66,9.17,9.7,1.58,18.8,3.98,18.8,16.62,0,9.4-7.44,15.79-21.43,15.79s-21.65-7.29-21.8-17.82Z" />
      <path className="cls-1" d="m366.76,49.78v-25.19h-7.52v-6.32h7.52V6.78l7.74-.9v12.41h8.8v6.32h-8.8v23.76c0,2.63.9,3.31,3.38,3.31h5.41v6.39h-7.97c-6.54,0-8.57-2.93-8.57-8.27Z" />
      <path className="cls-1" d="m390.6,42.79v-24.51h7.74v22.55c0,7.37,2.71,11.43,9.4,11.43s10.9-5.19,10.9-12.48v-21.5h7.74v39.77h-7.37l-.38-8.04h-.45c-1.96,5.49-7.14,8.72-13.31,8.72-9.02,0-14.28-5.41-14.28-15.94Z" />
      <path className="cls-1" d="m433.83,38.13c0-13.31,6.54-20.52,16.24-20.52,6.69,0,11.65,3.38,13.53,8.5h.45V3.25h7.74v54.81h-7.44l-.3-7.89h-.45c-1.88,5.11-6.84,8.57-13.53,8.57-9.7,0-16.24-7.29-16.24-20.6Zm30.15,2.26v-4.51c0-6.09-3.83-11.95-11.2-11.95-6.62,0-10.98,4.81-10.98,14.21s4.36,14.21,10.98,14.21c7.37,0,11.2-5.79,11.2-11.95Z" />
      <path className="cls-1" d="m482.62,3.25h7.74v8.95h-7.74V3.25Zm0,15.04h7.74v39.77h-7.74V18.28Z" />
      <path className="cls-1" d="m498.41,38.28c0-12.25,7.44-20.6,19.47-20.6s19.4,7.89,19.4,20.52-7.44,20.52-19.4,20.52-19.47-7.97-19.47-20.45Zm30.98-.08c0-8.95-4.36-14.13-11.5-14.13s-11.58,5.04-11.58,14.21,4.51,13.98,11.58,13.98,11.5-5.04,11.5-14.06Z" />
    </g>
  </svg>
);

Banner.propTypes = {
  className: PropTypes.string,
};

Banner.defaultProps = {
  className: '',
};

export default Banner;
