import React, { useContext } from 'react';
import * as propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ThemeContext from '../../contexts/themeContext';

function Card({
  title, subtitle, imgSrc, imgAlt, children, type, id,
}) {
  const themeContext = useContext(ThemeContext);

  return (
    <div
      className="
      w-full h-auto md:w-auto md:h-full aspect-card rounded-lg pb-1 my-2 mb-3 md:m-2 md:mr-7 md:relative transform md:hover:scale-105 hover:text-coolerOrange transition duration-500
    "
    >
      {id ? (
        <Link className="cursor-pointer" to={`projects/${type}/${id}`}>
          <div
            className={
              themeContext.theme === 'light'
                ? 'border-first'
                : 'border-first-dark'
            }
          >
            <div
              className={
                themeContext.theme === 'light'
                  ? 'border-second'
                  : 'border-second-dark'
              }
            >
              <h3 className="dark:text-white text-black text-cardTitle max-h-full md:max-w-3/4 pt-2 font-extrabold leading-none md:-mt-6 whitespace-pre-line">
                {title}
              </h3>
              <p className="dark:text-white text-black md:max-h-2.5 mb-1 md:mb-0 text-sm md:text-[0.965rem] mt-2 whitespace-pre-line">
                {subtitle}
              </p>
              {children}
              <img
                src={imgSrc}
                alt={imgAlt}
                className="aspect-manga w-full md:absolute bottom-0 rounded-lg -z-1"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="3.5"
                stroke="currentColor"
                className="w-6 h-6 absolute p-1 top-2 right-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                />
              </svg>
            </div>
          </div>
        </Link>
      ) : (
        <div
          className={
            themeContext.theme === 'light'
              ? 'border-first'
              : 'border-first-dark'
          }
        >
          <div
            className={
              themeContext.theme === 'light'
                ? 'border-second'
                : 'border-second-dark'
            }
          >
            <h3 className="dark:text-white text-black text-cardTitle max-h-full md:max-w-3/4 pt-2 font-extrabold leading-none md:-mt-6 whitespace-pre-line">
              {title}
            </h3>
            <p className="dark:text-white text-black md:max-h-2.5 mb-1 md:mb-0 text-sm md:text-[0.965rem] mt-2 whitespace-pre-line">
              {subtitle}
            </p>
            {children}
            <img
              src={imgSrc}
              alt={imgAlt}
              className="aspect-manga w-full md:absolute bottom-0 rounded-lg -z-1"
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default Card;

Card.propTypes = {
  title: propTypes.string.isRequired,
  subtitle: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  id: propTypes.string.isRequired,
  imgSrc: propTypes.string,
  imgAlt: propTypes.string,
  children: propTypes.element,
};

Card.defaultProps = {
  imgSrc:
    'https://images-na.ssl-images-amazon.com/images/I/51ACRYRD65L._SX333_BO1,204,203,200_.jpg',
  imgAlt: 'img',
  children: null,
};
