import React from 'react';
import PropTypes from 'prop-types';

const Page = ({ children }) => (
  <main className="w-full h-full md:overflow-auto lg:overflow-hidden overflow-y-auto overflow-x-hidden sm:overflow-x-auto scrollbar-w-2 scrollbar-thumb-rounded-full">
    {children}
  </main>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
