import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Contact from '../../components/ContactForm';
import Card from '../../components/UI/Card';
import graphismCards from './graphismCards';
import graphismCardsEn from './graphismCardsEn';
import letteringCards from './letteringCards';
import letteringCardsEn from './letteringCardsEn';

const Home = () => {
  const TABS = ['lettering', 'graphism', 'contact'];
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const [selectedGraphismCards, setSelectedGraphismCards] = useState([]);
  const [selectedLettetingCards, setSelectedLetteringCards] = useState([]);
  const { t } = useTranslation();

  // eslint-disable-next-line
  const Tab = ({ tab }) => (
    <button
      className={'cursor-pointer pt-1 pb-1 font-extrabold h-fit text-lg px-3 text-left md:text-2xl uppercase focus:outline-none hover:scale-105  '.concat(
        tab === selectedTab ? 'text-coolerOrange' : '',
      )}
      type="button"
      onClick={() => setSelectedTab(tab)}
      key={tab}
    >
      {t(`home.sidebar.${tab}`)}
    </button>
  );

  // eslint-disable-next-line
  const FuckingScrollableArea = ({ children }) => (
    <div
      className="
      h-full
      w-full

      scrollbar
      scrollbar-thumb-rounded-full
      scrollbar-track-transparent
      scrollbar-w-0

      md:pt-6
      md:pb-10
      md:pl-4
      md:px-0
      px-2

      flex
      flex-col
      md:flex-row

      overflow-y-scroll
      overflow-x-hidden

      md:overflow-y-hidden
      md:overflow-x-scroll"
    >
      {children}
    </div>
  );

  useEffect(() => {
    switch (i18next.language) {
      default:
      case 'fr':
        setSelectedGraphismCards(graphismCards);
        setSelectedLetteringCards(letteringCards);
        break;
      case 'en':
        setSelectedGraphismCards(graphismCardsEn);
        setSelectedLetteringCards(letteringCardsEn);
    }
  }, [t]);

  return (
    <>
      <div className="w-full h-full max-h-full flex flex-col lg:flex-row">
        <div className="h-1/10 w-full lg:h-fit lg:w-fit flex flex-row lg:flex-col justify-evenly xl:mr-8">
          {TABS.map((tab) => (
            <Tab tab={tab} key={tab} />
          ))}
        </div>
        {selectedTab === 'graphism' && (
          <FuckingScrollableArea>
            {selectedGraphismCards.map((graphismCard) => (
              <Card
                key={graphismCard.title + graphismCard.imgSrc}
                title={graphismCard.title}
                type="graphism"
                id={graphismCard.id}
                subtitle={graphismCard.subtitle}
                imgAlt={graphismCard.imgAlt}
                imgSrc={graphismCard.imgSrc}
              />
            ))}
          </FuckingScrollableArea>
        )}
        {selectedTab === 'lettering' && (
          <FuckingScrollableArea>
            {selectedLettetingCards.map((letteringCard) => (
              <Card
                key={letteringCard.title + letteringCard.imgSrc}
                title={letteringCard.title}
                type="lettering"
                id={letteringCard.id}
                subtitle={letteringCard.subtitle}
                imgAlt={letteringCard.imgAlt}
                imgSrc={letteringCard.imgSrc}
              />
            ))}
          </FuckingScrollableArea>
        )}
        {selectedTab === 'contact' && <Contact />}
      </div>
    </>
  );
};

export default Home;
