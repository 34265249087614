import React from 'react';
import PropTypes from 'prop-types';

const Picto = ({
  className,
}) => (
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 250 250"
    className={className}
  >
    <path d="M125.4,6C57.5,6,2.4,59.3,2.4,125c0,24.6,7.7,47.5,21,66.5L12,234l43.1-11.5c19.9,13.5,44.1,21.4,70.3,21.4 c67.9,0,122.9-53.2,122.9-118.9C248.3,59.3,193.3,6,125.4,6z M126.7,181.6c-32.5,0-58.8-25.5-58.8-56.9c0-31.4,26.3-56.9,58.8-56.9 c32.5,0,58.8,25.5,58.8,56.9C185.5,156.1,159.2,181.6,126.7,181.6z" />
  </svg>
);

Picto.propTypes = {
  className: PropTypes.string,
};

Picto.defaultProps = {
  className: '',
};

export default Picto;
