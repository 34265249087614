/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const letteringCards = [
  {
    title: 'Fall in Love \nwith Spring',
    type: 'Adaptation graphique',
    subtitle: 'Lettrage du one-shot.\nBruno DURAND — Chattochatto.',
    imgSrc: '/images/cover/filws.webp',
    imgFullSrc: '/images/cover/filws.webp',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Aoki est amoureux de Hashimoto, sa voisine de classe. Mais un jour, en lui empruntant sa
          gomme, il découvre avec horreur que l'élue de son cœur en aime un autre : le beau Ida, qui
          est assis juste devant lui. L'adolescent est tellement déçu qu'il ne réalise pas qu'il a
          fait tomber la gomme de sa camarade et que son supposé rival en amour est en train de la
          ramasser… C'est le début d'un énorme malentendu qui pourrait aboutir à la plus belle des
          histoires d'amour !
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-4">
          <span className="italic">Love Mix-Up</span>
          , c'est une romance moderne et bourrée d'humour
          qui, grâce à ses personnages aussi bienveillants que maladroits, ne manquera pas de faire
          chavirer vos cœurs !
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Maison d'édition :</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.akata.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Akata Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Scénario :</span>
              {' '}
              Wataru Hinekure
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Dessin :</span>
              {' '}
              Aruko
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction :</span>
              {' '}
              Aline Kukor
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Adaptation graphique :</span>
              {' '}
              Kelly Cotaquispe (NO HIT
              Studio)
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Couverture :</span>
              {' '}
              Clémence Aresu
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'dragon-metropolis',
    title: 'Dragon \nMetropolis',
    type: 'Adaptation graphique',
    subtitle: 'Lettrage du volume 5.\nBruno DURAND — Chattochatto.',
    imgSrc: '/images/cover/dm.webp',
    imgFullSrc: '/images/cover/dm.webp',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Le plan de Dimir se met en place sous le regard terrifié d'Artemis et de Zhengyi.
          Afin d'éviter l'effondrement de Dragon Dynasty,
          la famille royale intervient et découvre la vérité derrière ce qui semblait
          être à la base qu'une guerre fratricide.
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Maison d'édition :</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.chattochatto.com/"
                target="_blank"
                rel="noreferrer"
              >
                Chattochatto
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Scénario :</span>
              {' '}
              Barz JR.
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Dessin :</span>
              {' '}
              Barz JR.
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction :</span>
              {' '}
              Daphne Huang
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Adaptation graphique :</span>
              {' '}
              Bruno Durand (NO HIT
              Studio)
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Couverture :</span>
              {' '}
              Tom "spAde" Bertrand
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'self',
    title: 'Self___',
    type: 'Adaptation graphique',
    subtitle: 'Lettrage du one-shot.\nBruno DURAND — Chattochatto.',
    imgSrc: '/images/cover/self.webp',
    imgFullSrc: '/images/cover/self.webp',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Comment s'en sortir quand on a que des pensées négatives ?
          Quand malgré le succès, on se sent incapable, et pas à la hauteur ?
          Comment coexister avec soi-même quand le pragmatisme se confronte à son esprit enfantin ?
          Yanai nous raconte dans cette courte histoire comment l'acceptation de soi permet de se
          sortir d'une spirale infernale qu'est la dépression.
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Maison d'édition :</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.chattochatto.com/"
                target="_blank"
                rel="noreferrer"
              >
                Chattochatto
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Scénario :</span>
              {' '}
              Yanai
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Dessin :</span>
              {' '}
              Yanai
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction :</span>
              {' '}
              Emilie Gervaise
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Adaptation graphique :</span>
              {' '}
              Bruno Durand (NO HIT
              Studio)
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Couverture :</span>
              {' '}
              Bruno Durand (NO HIT
              Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'love-mix-up',
    title: 'Love Mix-Up',
    type: 'Adaptation graphique',
    subtitle: 'Lettrage des volumes 1 à 5.\nKelly COTAQUISPE — Akata Éditions.',
    imgSrc: '/images/cover/love-mu.webp',
    imgFullSrc: '/images/cover/love-mu.webp',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Aoki est amoureux de Hashimoto, sa voisine de classe. Mais un jour, en lui empruntant sa
          gomme, il découvre avec horreur que l'élue de son cœur en aime un autre : le beau Ida, qui
          est assis juste devant lui. L'adolescent est tellement déçu qu'il ne réalise pas qu'il a
          fait tomber la gomme de sa camarade et que son supposé rival en amour est en train de la
          ramasser… C'est le début d'un énorme malentendu qui pourrait aboutir à la plus belle des
          histoires d'amour !
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-4">
          <span className="italic">Love Mix-Up</span>
          , c'est une romance moderne et bourrée d'humour
          qui, grâce à ses personnages aussi bienveillants que maladroits, ne manquera pas de faire
          chavirer vos cœurs !
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Maison d'édition :</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.akata.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Akata Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Scénario :</span>
              {' '}
              Wataru Hinekure
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Dessin :</span>
              {' '}
              Aruko
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction :</span>
              {' '}
              Aline Kukor
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Adaptation graphique :</span>
              {' '}
              Kelly Cotaquispe (NO HIT
              Studio)
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Couverture :</span>
              {' '}
              Clémence Aresu
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'scums-wish',
    title: "Scum's Wish",
    type: 'Adaptation graphique',
    subtitle: 'Lettrage des volumes 1 à 3.\nBruno DURAND — Noeve Grafx.',
    imgSrc: '/images/cover/scums.webp',
    imgFullSrc: '/images/cover/scums.webp',
    imgCredits: '',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-4">
          <span className="font-bold">Mugi</span>
          {' '}
          et
          {' '}
          <span className="font-bold">Hanabi</span>
          {' '}
          forment le couple idéal : beaux, tendres et
          amoureux, tout le lycée les idolâtre. Mais leur relation repose en réalité sur un secret
          partagé : chacun n'est que le substitut de l'autre. Rongé par des amours impossibles,
          chacun se console dans les bras de l'autre et tente d'aller de l'avant. Mais se mentir,
          est-ce vraiment aller de l'avant ?
        </p>
        <div className="lg:grid lg:grid-cols-[47%_53%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Maison d'édition :</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://noeve.com/"
                target="_blank"
                rel="noreferrer"
              >
                Noeve Grafx
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Auteur :</span>
              {' '}
              Yokoyari Mengo
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Direction éditoriale :</span>
              {' '}
              Bertrand Brillois
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Conception et supervision graphique :</span>
              {' '}
              Kevin Druelle
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction :</span>
              {' '}
              Aurélie Brun
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Correction :</span>
              {' '}
              Anaïs Fourny
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Adaptation graphique :</span>
              {' '}
              Bruno Durand (NO HIT Studio)
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Finalisation intérieure :</span>
              {' '}
              Emma Poirrier
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'weeping-moon',
    title: 'Weeping\nMoon',
    type: 'Manga lettering',
    subtitle: 'Lettrage du manga.\nBruno DURAND — Tsubomi Éditions.',
    imgSrc: '/images/cover/wm.webp',
    imgFullSrc: '/images/cover/wm.webp',
    imgCredits: '',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Perdue dans un monde apocalyptique qui s'écroule, Moé-Moé, une jeune fille au
          regard obstiné, partage son quotidien avec un être monstrueux couvert de bandages.
          Malgré son apparence austère et ses sombres secrets, il fait preuve d'une infinie
          bienveillance envers sa jeune amie humaine qu'il considère comme sa fille...
        </p>
        <div className="lg:grid lg:grid-cols-[40%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Maison d'édition :</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.tsubomi.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Tsubomi Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Scénario :</span>
              {' '}
              Matsunoki Seven
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Dessin :</span>
              {' '}
              Matsunoki Seven
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction anglaise :</span>
              {' '}
              Jessica Gunawan
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction française :</span>
              {' '}
              Marylou Leclerc
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettrage :</span>
              {' '}
              Bruno Durand (NO HIT Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'teke-teke',
    title: 'Tant que les\ndémons sont là',
    type: 'Manga lettering',
    subtitle: 'Lettrage du manga.\nBruno DURAND — Tsubomi Éditions.',
    imgSrc: '/images/cover/teke.webp',
    imgFullSrc: '/images/cover/teke.webp',
    imgCredits: '',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Depuis un terrible accident, la discrète Hotaru Ashinozuka est épiée
          jour et nuit par des forces maléfiques. Résolue à ignorer ces abominations,
          elle poursuit sa scolarité avec l'espoir de vivre des jours meilleurs.
          Pourtant, sa rencontre avec Azami, une nouvelle élève à la beauté froide et
          ténébreuse, la poussera à devoir assumer son effroyable secret.
        </p>
        <div className="lg:grid lg:grid-cols-[40%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Maison d'édition :</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.tsubomi.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Tsubomi Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Scénario :</span>
              {' '}
              Minowa Bunko
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Dessin :</span>
              {' '}
              Minowa Bunko
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction anglaise :</span>
              {' '}
              Mikey Nguyen
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction française :</span>
              {' '}
              Marina Bonzi
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettrage :</span>
              {' '}
              Bruno Durand (NO HIT Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'meek',
    title: 'Meek\nle Transporteur',
    type: 'Manga lettering',
    subtitle: 'Lettrage du manga.\nBruno DURAND — Tsubomi Éditions.',
    imgSrc: '/images/cover/meek.webp',
    imgFullSrc: '/images/cover/meek.webp',
    imgCredits: '',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Poursuivant les traces de son père, Meek, un jeune transporteur voyage
          à travers de mystérieuses et terribles contrées pour délivrer ses
          cargaisons. Une seule règle : ne jamais en regarder le contenu...
        </p>
        <div className="lg:grid lg:grid-cols-[40%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Maison d'édition :</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.tsubomi.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Tsubomi Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Scénario :</span>
              {' '}
              Shun Miyake
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Dessin :</span>
              {' '}
              Shun Miyake
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction anglaise :</span>
              {' '}
              Jet Kint
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction française :</span>
              {' '}
              Myriam Ayachi
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettrage :</span>
              {' '}
              Bruno Durand (NO HIT Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'telomere',
    title: 'Telomere',
    type: 'Manga lettering',
    subtitle: 'Lettrage du manga.\nBruno DURAND — Tsubomi Éditions.',
    imgSrc: '/images/cover/telo.webp',
    imgFullSrc: '/images/cover/telo.webp',
    imgCredits: '',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Un violent virus a décimé l'entièreté de la population mondiale, 20 ans plus tard,
          des milliards de zombies errent sur la terre.
          Kou et sa jumelle Yuki voyagent à travers un Japon dévasté et mènent leurs
          propres expéditions. Ils devront faire face à de nombreux dangers et à
          une toute nouvelle menace...
        </p>
        <div className="lg:grid lg:grid-cols-[40%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Maison d'édition :</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.tsubomi.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Tsubomi Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Scénario :</span>
              {' '}
              Kakio Tsurukawa
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Dessin :</span>
              {' '}
              Kakio Tsurukawa
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction anglaise :</span>
              {' '}
              Jessica Gunawan
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction française :</span>
              {' '}
              Kevin Stocker
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettrage :</span>
              {' '}
              Bruno Durand (NO HIT Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'eternal-blue',
    title: 'Eternal Blue',
    type: 'Manga lettering',
    subtitle: 'Lettrage du manga.\nBruno DURAND — Tsubomi Éditions.',
    imgSrc: '/images/cover/eb.webp',
    imgFullSrc: '/images/cover/eb.webp',
    imgCredits: '',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Ao l'exigeante et sarcastique patronne ne perd pas de temps lorsqu'il s'agit
          de mettre Mikio, le nouvel employé de la Royal Library, à rude épreuve.
          À la fois gardiens du savoir et protecteurs de la santé mentale des lecteurs,
          ils se vouent ensemble à une lutte abracadabrante contre de redoutables ennemis :
          les Livres Maléfiques.
        </p>
        <div className="lg:grid lg:grid-cols-[40%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Maison d'édition :</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.tsubomi.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Tsubomi Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Scénario :</span>
              {' '}
              Mimishiki
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Dessin :</span>
              {' '}
              Mimishiki
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction anglaise :</span>
              {' '}
              Jessica Gunawan
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction française :</span>
              {' '}
              Marina Bonzi
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Lettrage :</span>
              {' '}
              Bruno Durand (NO HIT Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'vampeerz',
    title: 'Vampeerz',
    type: 'Adaptation graphique',
    subtitle: 'Lettrage du volume 1.\nKelly COTAQUISPE — Akata Éditions.',
    imgAlt: 'This is an alt',
    imgSrc: '/images/cover/vamp.webp',
    imgFullSrc: '/images/cover/vamp.webp',
    imgCredits: 'Crédits: Akata Éditions',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Le jour des funérailles de sa grand-mère, Ichika a un coup de foudre pour une mystérieuse
          étrangère du nom d’Aria. Mais il s’avère que cette fascinante nouvelle venue
          est en réalité une vampire millénaire. La jeune fille se souvient alors des paroles
          de son aïeule : « La personne idéale est celle qui te dérobera ton cœur. »
          Pour une humaine qui n’avait encore jamais connu l’amour, est-ce bien raisonnable
          de succomber aux charmes d’une créature de la nuit ? Et quelles sont les véritables
          intentions de cette dernière ?
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-4">
          Dans
          {' '}
          <span className="italic">Vampeerz</span>
          , série mêlant habilement romance, action et horreur,
          suivez le quotidien d’une adolescente qui tombe amoureuse
          d’une vampire désirant mourir… Avec une narration fluide et
          juste ce qu’il faut de désinvolture, AKILI propose une série particulièrement efficace.
        </p>
        <div className="lg:grid lg:grid-cols-[30%_70%] lg:gap-8 pb-4">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Maison d'édition :</span>
              {' '}
              <a
                className="text-coolerOrange"
                href="https://www.akata.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Akata Éditions
              </a>
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Auteur :</span>
              {' '}
              AKILI
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Couverture :</span>
              {' '}
              Clémence Aresu
            </p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Traduction :</span>
              {' '}
              Vincent Marcantognini
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              <span className="font-bold">Adaptation graphique :</span>
              {' '}
              Kelly Cotaquispe (NO HIT
              Studio)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'berserk-of-gluttony',
    title: 'Berserk of\n Gluttony',
    type: 'Adaptation graphique',
    subtitle: 'Lettrage des volumes 1 & 2.\nBruno DURAND — Mahô Éditions.',
    imgAlt: 'Couverture de Berserk of Gluttony volume 01',
    imgSrc: '/images/cover/bog.webp',
    imgFullSrc: '/images/cover/bog.webp',
    imgCredits: (
      <div>
        <p className="text-md dark:text-white text-black text-justify">
          <span className="font-bold">Nom du manga :</span>
          {' '}
          Berserk of Gluttony
        </p>
        <p className="text-md dark:text-white text-black text-justify">
          <span className="font-bold">Maison d'édition :</span>
          {' '}
          <a
            className="text-coolerOrange"
            href="https://www.maho-editions.com/"
            target="_blank"
            rel="noreferrer"
          >
            Mahô Éditions
          </a>
        </p>
        <p className="text-md dark:text-white text-black text-justify pt-2">
          <span className="font-bold">Histoire :</span>
          {' '}
          Ichika ISSHIKI
        </p>
        <p className="text-md dark:text-white text-black text-justify">
          <span className="font-bold">Dessin :</span>
          {' '}
          Daisuke TAKINO
        </p>
        <p className="text-md dark:text-white text-black text-justify">
          <span className="font-bold">Design original :</span>
          {' '}
          <span className="italic">fame</span>
        </p>
        <p className="text-md dark:text-white text-black text-justify pt-2">
          <span className="font-bold">Directeur éditorial :</span>
          {' '}
          Aurélien Martinez
        </p>
        <p className="text-md dark:text-white text-black text-justify">
          <span className="font-bold">Équipe commerciale :</span>
          {' '}
          <br />
          Jérémy Touzet, Caroline Delmon
        </p>
        <p className="text-md dark:text-white text-black text-justify pt-2">
          <span className="font-bold">Traduction :</span>
          {' '}
          Yukio Reuter
        </p>
        <p className="text-md dark:text-white text-black text-justify">
          <span className="font-bold">Adaptation graphique :</span>
          {' '}
          <br />
          Bruno Durand (NO HIT Studio)
        </p>
        <p className="text-md dark:text-white text-black text-justify">
          <span className="font-bold">Composition et mise en page :</span>
          {' '}
          Ludovic Fily
        </p>
      </div>
    ),
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <h1 className="text-lg dark:text-white text-black text-justify pb-4 font-bold">
          Les coulisses du lettrage et des retouches du manga Berserk of Gluttony tome 01,
          disponible chez
          {' '}
          <a
            className="text-coolerOrange"
            href="https://www.maho-editions.com/"
            target="_blank"
            rel="noreferrer"
          >
            Mahô Éditions
          </a>
          .
        </h1>
        <div className="lg:grid lg:grid-cols-[60%_40%] lg:gap-8 pb-2">
          <div className="lg:row-start-1 lg:col-start-1 relative">
            <p className="text-md dark:text-white text-black text-justify pb-2 md:pr-4">
              Il a été choisi avec l'éditeur d'adapter l'ensemble des
              onomatopées originales en français (et non
              pas de sous-titrer comme c'est assez couramment fait) afin que le lecteur puisse avoir
              une meilleure immersion. Il a donc fallu "reconstruire" une bonne partie des planches
              (C'est à dire redessiner les parties du dessins qui sont écrasées par les onomatopées
              japonaises).
            </p>
            <h3 className="text-lg dark:text-white text-black text-justify pt-2 font-bold">
              Reconstructions :
            </h3>
            <p className="text-md dark:text-white text-black text-justify pb-2 md:pr-4">
              Une des premières pages que vous verrez dans ce tome, c'est l'image suivante,
              qui est la première page du volume 01 (hors sommaire et pages annexes).
              On remarque que sur cette page que les bulles passes au dessus des onomatopées.
              La première étape est donc d'isoler les bulles du reste du fond afin de pouvoir
              reconstruire plus facilement les onomatopées puis dans un second temps,
              d'ajouter celles françaises.
            </p>
            <p className="text-md dark:text-white text-black text-justify pb-2 md:pr-4">
              Aussi, on remarque que les bulles ont un effet de lueur qui doit être pris en compte.
            </p>
            <p className="text-md dark:text-white text-black text-justify pb-2 md:pr-4">
              1ère étape donc : Isoler les bulles et remettre l'effet de lueur. Ça peut sembler
              difficile ici, mais Photoshop a une option très pratique pour ça, la sélection par
              plage de couleur.
            </p>
            <img
              src="https://pbs.twimg.com/media/FACcN5MVkAEfEVl?format=jpg&name=small"
              className="flex mx-auto pb-2 w-64"
              alt=""
            />
            <p className="text-sm italic text-center">Sélection par couleur sur Photoshop</p>
          </div>
          <div className="md:pr-2">
            <img
              src="https://pbs.twimg.com/media/FACTxWMUUAQpHF5?format=jpg&name=4096x4096"
              className="pb-2"
              alt=""
            />
            <p className="text-sm pb-4 italic">Page 03 du tome 01 VO de Berserk of Gluttony.</p>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-[60%_40%] lg:gap-8">
          <div className="lg:row-start-1 lg:col-start-2 relative">
            <img
              src="https://pbs.twimg.com/media/FACYIuzVQAAomn-?format=jpg&name=4096x4096"
              className="pb-2 md:pr-4"
              alt=""
            />
            <p className="text-sm pb-4 italic">Bulles isolées avec ajout de lueur externe.</p>
          </div>
          <div>
            <p className="text-md dark:text-white text-black text-justify pb-2">
              Tous les pixels noirs ont été automatiquement sélectionnés autour et après un peu de
              nettoyage, il a juste fallu copier-coller, appliquer un effet de lueur sur
              les calques et mettre tout ça en haut du fichier. Voilà le résultat obtenu une
              fois le tout isolé (voir l'image suivante).
            </p>
            <p className="text-md dark:text-white text-black text-justify">
              Ensuite, il ne reste "plus qu'à" reconstruire ce qu'il y a en dessous (plus ou moins
              grossièrement étant donné que certaines zones seront
              recouvertes par les onomatopées françaises) puis
              ajouter les nouvelles onomatopées. Voici donc la planche nettoyée et finalisée :
            </p>
            <div className="lg:grid lg:grid-cols-2 lg:gap-1 py-2">
              <div className="lg:row-start-1 lg:col-start-1 relative">
                <img
                  src="https://pbs.twimg.com/media/FACYa8zVkAQOK4p?format=jpg&name=4096x4096"
                  alt=""
                />
                <p className="text-sm pt-2 pb-2 italic">Page nettoyée</p>
              </div>
              <div>
                <img
                  src="https://pbs.twimg.com/media/FACYa-eVgAMimZP?format=jpg&name=4096x4096"
                  alt=""
                />
                <p className="text-sm pt-2 italic">Ajout des onomatopées françaises</p>
              </div>
            </div>
          </div>
        </div>
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Beaucoup d'autres planches ont été traitées de la même façon que celle ci-dessus, mais le
          travail de lettreur n'est pas celui d'illustrateur et certaines images
          peuvent être complexes à redessiner et c'est
          là qu'il faut être astucieux.
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Comment adapter l'onomatopée la plus fidèlement possible sans que avoir à redessiner toute
          une case ? Nous avons vu juste avant une planche nettoyée complètement, mais lorsque c'est
          trop complexe ou chronophage, les reconstructions ne sont faites que sur les parties
          visibles pour le lecteur, et ça donne ce résultat :
        </p>
        <div className="lg:grid lg:grid-cols-3 lg:gap-1 py-2">
          <div>
            <img src="https://pbs.twimg.com/media/FACaMj6VIAUdIR6?format=jpg&name=large" alt="" />
            <p className="text-sm pt-2 pb-2 italic">1. Page originale</p>
          </div>
          <div>
            <img src="https://pbs.twimg.com/media/FACaSDXVQAweX3G?format=jpg&name=large" alt="" />
            <p className="text-sm pt-2 pb-2 italic">2. Retrait superficiel des onomatopées VO</p>
          </div>
          <div>
            <img src="https://pbs.twimg.com/media/FACaUgZVEAgdueU?format=jpg&name=large" alt="" />
            <p className="text-sm pt-2 pb-2 pl-2 italic">3. Ajout des onomatopées françaises</p>
          </div>
        </div>
        <h3 className="text-lg dark:text-white text-black text-justify pt-2 pb-2 font-bold">
          Bulles et polices :
        </h3>
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Lors de la définition de la charte typographique avec l'éditeur,
          nous avons choisis de mettre l'accent sur la simplicité de lecture avec
          un choix de polices réduit que les lecteurs ont l'habitude de lire et
          qui est donc confortable pour les yeux. On retrouve forcément
          le traditionnel Komika Jim pour les bulles, cases, pensées...
          que vous avez pu notamment voir sur plein de titres français comme
          Jujutsu Kaisen, My Hero Academia ou Kingdom.
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Voilà un exemple de cette dernière :
        </p>
        <img
          src="https://pbs.twimg.com/media/FACawrYUcAM3Lfe?format=jpg&name=small"
          className="flex mx-auto pb-2 w-80"
          alt=""
        />
        <p className="text-md dark:text-white text-black text-justify pb-2">
          L'autre police importante, c'est la police utilisée lorsque Greed parle. Sa voix semblant
          plus rauque par la police japonaise, notre choix s'est donc porté sur une police que vous
          connaissez si vous avez lu le manga
          {' '}
          <a
            className="text-coolerOrange"
            href="https://twitter.com/Blackfury__"
            target="_blank"
            rel="noreferrer"
          >
            Blackfury
          </a>
          {' '}
          ou de nombreux shonen ou seinen, CC Meanwhile.
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-2">
          Malheureusement, pas d'exemple ici avec Berserk of Gluttony pour éviter tout spoil !
        </p>
        <p className="text-md dark:text-white text-black text-justify pb-2">
          C'est globalement tout pour les polices principales. Il y en a quelques autres
          situationnelles, mais c'est assez rare dans l'ensemble du volume.
        </p>
        <p className="text-md dark:text-white text-black text-justify pt-2">
          Merci d'avoir lu l'article !
        </p>
        <p className="text-md dark:text-white text-black text-justify italic">
          Article rédigé par Bruno DURAND, le 24 septembre 2021.
          {' '}
          <a
            className="text-coolerOrange"
            href="https://twitter.com/Blackfury__"
            target="_blank"
            rel="noreferrer"
          >
            Voir le thread Twitter original
          </a>
        </p>
        <p className="text-md dark:text-white text-black text-justify italic">
          Dernière modification le 21 décembre 2022.
        </p>
      </div>
    ),
  },
  {
    title: 'Black Fury\n Adventures',
    type: 'Adaptation graphique',
    subtitle: 'Lettrage des volume 1 & 2.\nBruno DURAND — Mahô Éditions.',
    imgAlt: 'This is an alt',
    imgSrc: '/images/cover/bf.webp',
    imgFullSrc: '/images/cover/bf.webp',
    imgCredits: 'Crédits: ©Ella DUBOL',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <div className="text-base max-w-prose mx-auto lg:max-w-none">
          <p className="text-lg dark:text-white text-black ">
            Bucaneers are the shipmates of the big madness. Desire me grog, ye heavy-hearted pin!
            The shark marks with horror, view the galley.
          </p>
        </div>
        <div className="mt-5 prose prose-indigo dark:text-white text-black mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
          <p>
            Divided, melted pudding is best flavored with salted hollandaise sauce. With blueberries
            drink peppermint tea. Blood oranges can be tossed with raw pumpkin seeds, also try
            seasoning the platter with bourbon.
          </p>

          <p className="mt-5 mb-5">
            This anomaly has only been yearned by a spheroid sun. Why does the sonic shower harvest?
            Sensors meet with stigma at the fantastic habitat accelerativemake it so! Creature of a
            harmless starlight travel, discover the sensor.
          </p>
          <p className="mt-5 mb-5">
            Dictum magnis risus phasellus vitae quam morbi. Quis lorem lorem arcu, metus, egestas
            netus cursus. In.
          </p>
          <p className="mt-5 mb-5">
            Rhoncus nisl, libero egestas diam fermentum dui. At quis tincidunt vel ultricies.
            Vulputate aliquet velit faucibus semper. Pellentesque in venenatis vestibulum
            consectetur nibh id. In id ut tempus egestas. Enim sit aliquam nec, a. Morbi enim
            fermentum lacus in. Viverra.
          </p>
          <p className="mt-5 mb-5">
            To the instant shrimps add chicken lard, melon, vinegar and chilled ground beef. With
            spinach drink plain vinegar. Try roasting pork shoulder soup garnished with gold
            tequila. Try sliceing nachos ricotta mixed with rice vinegar. Try enameling the red wine
            mackerels with aged anchovy essence and triple sec, roasted.
          </p>

          <h3>How we helped</h3>
          <p>
            Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in
            volutpat, diam. Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet
            sem est phasellus eu proin massa, lectus. Diam rutrum posuere donec ultricies non morbi.
            Mi a platea auctor mi.
          </p>
          <p>
            Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur
            neque tristique pellentesque. Blandit amet, sed aenean erat arcu morbi.
          </p>
        </div>
      </div>
    ),
  },
  {
    title: 'Contes\n merveilleux du printemps',
    type: 'Adaptation graphique',
    subtitle: 'Lettrage du one-shot.\nBruno DURAND — Mahô Éditions.',
    imgAlt: 'This is an alt',
    imgSrc: '/images/cover/cmp.webp',
    imgFullSrc: '/images/cover/cmp.webp',
    imgCredits: 'Crédits: ©Élie COPTAIRE',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <div className="text-base max-w-prose mx-auto lg:max-w-none">
          <p className="text-lg dark:text-white text-black ">
            Bucaneers are the shipmates of the big madness. Desire me grog, ye heavy-hearted pin!
            The shark marks with horror, view the galley.
          </p>
        </div>
        <div className="mt-5 prose prose-indigo dark:text-white text-black mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
          <p>
            Divided, melted pudding is best flavored with salted hollandaise sauce. With blueberries
            drink peppermint tea. Blood oranges can be tossed with raw pumpkin seeds, also try
            seasoning the platter with bourbon.
          </p>

          <p className="mt-5 mb-5">
            This anomaly has only been yearned by a spheroid sun. Why does the sonic shower harvest?
            Sensors meet with stigma at the fantastic habitat accelerativemake it so! Creature of a
            harmless starlight travel, discover the sensor.
          </p>
          <p className="mt-5 mb-5">
            Dictum magnis risus phasellus vitae quam morbi. Quis lorem lorem arcu, metus, egestas
            netus cursus. In.
          </p>
          <p className="mt-5 mb-5">
            Rhoncus nisl, libero egestas diam fermentum dui. At quis tincidunt vel ultricies.
            Vulputate aliquet velit faucibus semper. Pellentesque in venenatis vestibulum
            consectetur nibh id. In id ut tempus egestas. Enim sit aliquam nec, a. Morbi enim
            fermentum lacus in. Viverra.
          </p>
          <p className="mt-5 mb-5">
            To the instant shrimps add chicken lard, melon, vinegar and chilled ground beef. With
            spinach drink plain vinegar. Try roasting pork shoulder soup garnished with gold
            tequila. Try sliceing nachos ricotta mixed with rice vinegar. Try enameling the red wine
            mackerels with aged anchovy essence and triple sec, roasted.
          </p>

          <h3>How we helped</h3>
          <p>
            Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in
            volutpat, diam. Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet
            sem est phasellus eu proin massa, lectus. Diam rutrum posuere donec ultricies non morbi.
            Mi a platea auctor mi.
          </p>
          <p>
            Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur
            neque tristique pellentesque. Blandit amet, sed aenean erat arcu morbi.
          </p>
        </div>
      </div>
    ),
  },
  {
    title: 'Zombie\n From Now On !',
    type: 'Adaptation graphique',
    subtitle: 'Lettrages des volume 1 & 2.\nBruno DURAND — Chattochatto.',
    imgAlt: 'This is an alt',
    imgSrc: '/images/cover/zfno.webp',
    imgFullSrc: '/images/cover/zfno.webp',
    imgCredits: 'Crédits: ©Théo TOUALETTE',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <div className="text-base max-w-prose mx-auto lg:max-w-none">
          <p className="text-lg dark:text-white text-black ">
            Bucaneers are the shipmates of the big madness. Desire me grog, ye heavy-hearted pin!
            The shark marks with horror, view the galley.
          </p>
        </div>
        <div className="mt-5 prose prose-indigo dark:text-white text-black mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
          <p>
            Divided, melted pudding is best flavored with salted hollandaise sauce. With blueberries
            drink peppermint tea. Blood oranges can be tossed with raw pumpkin seeds, also try
            seasoning the platter with bourbon.
          </p>

          <p className="mt-5 mb-5">
            This anomaly has only been yearned by a spheroid sun. Why does the sonic shower harvest?
            Sensors meet with stigma at the fantastic habitat accelerativemake it so! Creature of a
            harmless starlight travel, discover the sensor.
          </p>
          <p className="mt-5 mb-5">
            Dictum magnis risus phasellus vitae quam morbi. Quis lorem lorem arcu, metus, egestas
            netus cursus. In.
          </p>
          <p className="mt-5 mb-5">
            Rhoncus nisl, libero egestas diam fermentum dui. At quis tincidunt vel ultricies.
            Vulputate aliquet velit faucibus semper. Pellentesque in venenatis vestibulum
            consectetur nibh id. In id ut tempus egestas. Enim sit aliquam nec, a. Morbi enim
            fermentum lacus in. Viverra.
          </p>
          <p className="mt-5 mb-5">
            To the instant shrimps add chicken lard, melon, vinegar and chilled ground beef. With
            spinach drink plain vinegar. Try roasting pork shoulder soup garnished with gold
            tequila. Try sliceing nachos ricotta mixed with rice vinegar. Try enameling the red wine
            mackerels with aged anchovy essence and triple sec, roasted.
          </p>

          <h3>How we helped</h3>
          <p>
            Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in
            volutpat, diam. Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet
            sem est phasellus eu proin massa, lectus. Diam rutrum posuere donec ultricies non morbi.
            Mi a platea auctor mi.
          </p>
          <p>
            Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur
            neque tristique pellentesque. Blandit amet, sed aenean erat arcu morbi.
          </p>
        </div>
      </div>
    ),
  },
];

export default letteringCards;
