import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import Aktis from '../images/Partners/Aktis';
import MahoJSX from '../images/Partners/Maho';
import Noeve from '../images/Partners/Noeve';
import Delitoon from '../images/Partners/delitoon.svg';

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      message: '',
    },
    onSubmit: async (data) => {
      setIsLoading(true);
      await emailjs.send('service_z28qad5', 'template_kokvtl8', data, 'BqVIlSzqdQlYkdMPU');
      setIsLoading(false);
      formik.resetForm();
    },
  });

  const INPUT_COMMON_CLASSES = 'py-3 px-4 block w-full shadow-md focus:ring-coolerOrange focus:border-coolerOrange border-gray-300 rounded-lg bg-white transition duration-500 text-black';
  const LABEL_COMMON_CLASSES = 'block text-md font-medium text-black dark:text-white transition duration-500 font-bold';

  return (
    <div className="w-full flex flex-col xl:flex-row xl-mr-0 lg:mr-4 overflow-y-auto scrollbar-w-2 scrollbar-thumb-rounded-full">
      {/* Info */}
      <div className="w-full xl:w-2/5 flex flex-col mb-12 md:ml-18 md:pr-12 xl:pr-8 lg:border-r">
        <div className="flex flex-col">
          <div className="w-full mb-2 mt-2">
            <span className="text-xl font-medium whitespace-pre-line flex flex-col">
              <span className="mb-2 leading-6">{t('contact.info.directReach')}</span>
              <a
                href="mailto:contact@nohit.studio"
                className="leading-5 text-coolerOrange hover:underline"
              >
                contact@nohit.studio
              </a>
              <span className="flex flex-col mt-6">
                <span className="leading-6 mb-2">{t('contact.info.twitter')}</span>
                <a
                  href="https://twitter.com/nohitstudio"
                  className="leading-5 text-coolerOrange hover:underline"
                >
                  @nohitstudio
                </a>
              </span>
            </span>
          </div>
        </div>
        <div className="flex flex-col mt-2">
          <span className="h-1/5 text-xl font-medium mt-4">{t('contact.info.partners')}</span>
          <div className="flex flex-row flex-wrap items-center pb-2 h-4/5">
            <a href="https://chattochatto.com/" target="_blank" rel="noreferrer" className="h-16 mx-2 pb-2 hover:scale-110 transition duration-500">
              <img
                src="https://uploads-ssl.webflow.com/63e3cfee6e0c81abbc6b9105/64da62900da6f02bcdaf1a17_Logo%20chattochatto%20HD-p-500.png"
                alt="Chatto Chatto"
                className="h-full"
              />
            </a>
            <a href="https://www.akata.fr" target="_blank" rel="noreferrer" className="h-16 mx-2 pb-2 hover:scale-110 transition duration-500">
              <img
                src="https://www.akata.fr/sites/default/files/akata-logo2020.png"
                alt="Akata"
                className="h-full"
              />
            </a>
            <a href="https://noeve.com/collections/grafx" target="_blank" rel="noreferrer" className="h-20 mx-2 pb-2 hover:scale-110 transition duration-500">
              <Noeve className="fill-black dark:fill-white h-full transition duration-500" />
            </a>
            <a href="https://maho-editions.com" target="_blank" rel="noreferrer" className="h-8 mx-2 pb-2 hover:scale-110 transition duration-500">
              <MahoJSX className="fill-black dark:fill-white h-full transition duration-500" />
            </a>
            <a href="https://www.delitoon.com/" target="_blank" rel="noreferrer" className="h-10 mx-2 pb-2 hover:scale-110 transition duration-500">
              <img src={Delitoon} alt="Delitoon" className="h-full transition duration-500" />
            </a>
            <a href="https://www.aktislab.fr" target="_blank" rel="noreferrer" className="h-6 mx-2 hover:scale-110 transition duration-500">
              <Aktis className="fill-black dark:fill-white h-full transition duration-500" />
            </a>
          </div>
        </div>
      </div>
      {/* Contact form */}
      <div className="w-full xl:w-3/5 flex flex-col">
        <div className="p-4 pl-0 overflow-hidden w-full">
          <div className="max-w-xl xl:mx-auto">
            <div className="">
              <span className="text-xl font-extrabold w-full text-left">
                {t('contact.form.head')}
              </span>
              <form
                onSubmit={formik.handleSubmit}
                className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <div>
                  <span htmlFor="first-name" className={LABEL_COMMON_CLASSES}>
                    {t('contact.form.firstName')}
                  </span>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      autoComplete="given-name"
                      className={INPUT_COMMON_CLASSES}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div>
                  <span htmlFor="last-name" className={LABEL_COMMON_CLASSES}>
                    {t('contact.form.lastName')}
                  </span>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      autoComplete="family-name"
                      className={INPUT_COMMON_CLASSES}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <span htmlFor="subject" className={LABEL_COMMON_CLASSES}>
                    {t('contact.form.subject')}
                  </span>
                  <div className="mt-1">
                    <input
                      id="subject"
                      name="subject"
                      type="text"
                      className={INPUT_COMMON_CLASSES}
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <span htmlFor="email" className={LABEL_COMMON_CLASSES}>
                    {t('contact.form.email')}
                  </span>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className={INPUT_COMMON_CLASSES}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <span htmlFor="message" className={LABEL_COMMON_CLASSES}>
                    {t('contact.form.message')}
                  </span>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      className={'h-24 resize-none scrollbar-thumb-rounded-full scrollbar-w-2 '.concat(
                        INPUT_COMMON_CLASSES,
                      )}
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2 text-right">
                  <button
                    type="submit"
                    className="
                    px-6 py-3
                    rounded-md
                    shadow-sm
                    font-bold
                    text-white
                    bg-coolerOrange
                    transition duration-500
                    hover:scale-105
                    focus:outline-none
                    disabled:cursor-not-allowed disabled:bg-coolerOrange-disabled
                  "
                    disabled={
                      !formik.values.firstName
                      || !formik.values.lastName
                      || !formik.values.email
                      || !formik.values.subject
                      || !formik.values.message
                    }
                  >
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 38 38"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#fff"
                      className={'mr-4 '.concat(isLoading ? 'block' : 'hidden')}
                    >
                      <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="4">
                          <circle strokeOpacity=".3" cx="18" cy="18" r="18" />
                          <path d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform
                              attributeName="transform"
                              type="rotate"
                              from="0 18 18"
                              to="360 18 18"
                              dur="2s"
                              repeatCount="indefinite"
                            />
                          </path>
                        </g>
                      </g>
                    </svg>
                    {t('contact.form.submit')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
