import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ThemeContext from '../contexts/themeContext';
import Moon from '../images/Moon';
import Sun from '../images/Sun';
import Picto from '../images/Picto';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const themeContext = useContext(ThemeContext);
  return (
    <footer className="h-10 md:pt-9 pb-1 flex items-center justify-evenly md:justify-auto">
      <div className="flex md:mx-4 w-full justify-between">
        <div className="flex items-center">
          <Picto className="h-4 mx-2 md:mr-10 transition duration-500 fill-black dark:fill-white" />
          <div className="hidden: sm:flex items-center">
            <span className="mr-12 hidden md:block">
              {`© ${new Date().getFullYear()} NO HIT STUDIO`}
            </span>
            <Link to="/terms" className="hidden sm:block mr-12">{t('footer.terms')}</Link>
            <a href="https://twitter.com/nohitstudio" target="_blank" rel="noreferrer" className="hidden mr-12 xl:block">Twitter</a>
            <a href="https://www.linkedin.com/company/no-hit-studio" target="_blank" rel="noreferrer" className="hidden xl:block">LinkedIn</a>
            {i18n.language === 'en' ? (
              <button
                type="button"
                className="md:ml-12 px-2 outline-none focus:outline-none"
                onClick={() => {
                  i18n.changeLanguage('fr');
                  localStorage.setItem('NHS-language', 'fr');
                }}
              >
                {t('footer.french')}
              </button>
            ) : (
              <button
                type="button"
                className="md:ml-12 px-2 outline-none focus:outline-none"
                onClick={() => {
                  i18n.changeLanguage('en');
                  localStorage.setItem('NHS-language', 'en');
                }}
              >
                {t('footer.english')}
              </button>
            )}
          </div>

        </div>
        <div className="flex">
          <div className="hidden mr-12 xl:flex xl:items-center">
            <a href="https://www.aktislab.fr" target="_blank" rel="noreferrer" className="mr-2">
              {t('footer.creator')}
              AktisLab
            </a>
            <a href="https://www.aktislab.fr" target="_blank" rel="noreferrer">
              <img className="h-5 w-5" src="/images/icons/AktisLab.svg" alt="AktisLab logo" />
            </a>
          </div>
          <button
            className="xl:mr-8 focus:outline-none"
            type="button"
            onClick={() => themeContext.changeTheme()}
          >
            {themeContext.theme === 'dark'
              ? <Sun className="h-5" />
              : <Moon className="h-5" />}
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
