import propTypes from 'prop-types';
import React from 'react';

const Back = ({
  className,
}) => (
  <svg className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M 24 11.98 C 24 12.926 23.233 13.694 22.287 13.694 L 5.852 13.694 L 8.069 15.911 C 9.018 16.828 8.62 18.429 7.351 18.792 C 6.742 18.966 6.085 18.791 5.645 18.335 L 0.502 13.192 C -0.167 12.522 -0.167 11.437 0.502 10.768 L 5.645 5.624 C 6.594 4.708 8.18 5.162 8.5 6.443 C 8.642 7.016 8.48 7.624 8.069 8.048 L 5.852 10.266 L 22.287 10.266 C 23.233 10.266 24 11.033 24 11.98 Z" data-name="Left" />
  </svg>

);

Back.propTypes = {
  className: propTypes.string,
};

Back.defaultProps = {
  className: '',
};

export default Back;
