import i18next from 'i18next';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Back from '../../images/Back';

const Terms = () => {
  const [isFr, setIsFr] = useState(i18next.language === 'fr');

  i18next.on('languageChanged', () => {
    setIsFr(i18next.language === 'fr');
  });

  return (
    <div className="h-full w-full flex justify-center relative">
      <Link to="/" className="flex absolute top-6 sm:top-0 left-0">
        <Back className="w-8 fill-black dark:fill-white transition duration-500" />
      </Link>
      {/* eslint-disable */}
      <div className="w-full md:w-4/5 pr-1 md:pr-4 md:mr-10 xl:mr-20 scrollbar-w-2 overflow-y-scroll scrollbar-thumb-rounded-full">
        {isFr ? (
          <div className="flex flex-col text-lg">
            <div className="w-full flex flex-col items-center mb-8">
              <span className="text-center font-extrabold">
                Conditions générales d&apos;utilisation
              </span>
              <span className="text-center italic">Mis à jour le : 21/02/2022</span>
            </div>
            <span className="font-extrabold">Préambule</span>
            <p className="text-justify mb-4">
              NO HIT Studio est une plate-forme web, disponible à l&apos;adresse{' '}
              <a href="/" className="text-coolerOrange font-bold">
                https://nohit.studio
              </a>{' '}
              (le « <span className="font-extrabold">Site</span> »).
            </p>
            <p className="text-justify mb-4">
              Ce Site est édité par Durand, entrepreneur individuel domicilié au 20 Allée du Bugey,
              69330 Jonage, FRANCE. Cet entrepreneur est immatriculé au registre du commerce et des
              sociétés de Lyon sous le numéro d&apos;identification 833323165, (ci-après «{' '}
              <span className="font-extrabold">l&apos;Editeur </span>»). NO HIT Studio est
              accessibles aux utilisateurs (le ou les «
              <span className="font-extrabold">Utilisateurs</span>»).
            </p>
            <p className="text-justify mb-4">
              NO HIT Studio est hébergé par OVH, dont le siège social est situé au 2 Rue Kellermann,
              59100 Roubaix, France. Cet hébergeur peut être contacté à cette adresse électronique :{' '}
              <a className="text-coolerOrange font-bold" href="mailto:contact@ovh.net">
                contact@ovh.net
              </a>
              .
            </p>
            <p className="text-justify mb-4">
              L&apos;objet des présentes Conditions Générales d&apos;Utilisation (les «
              <span className="font-extrabold">Conditions</span>» ou prises dans leur ensemble, le «
              <span className="font-extrabold">Contrat</span>») est de définir les termes et les
              conditions régissant les relations entre les Utilisateurs et Durand. En cas de
              non-respect des termes des présentes Conditions, Durand se réserve le droit de prendre
              toute mesure de nature à préserver ses intérêts et notamment à en assurer
              l&apos;exécution.
            </p>
            <p className="text-justify mb-4">
              L&apos;accès et l&apos;utilisation du site sont soumis à l&apos;acceptation et au
              respect des présentes Conditions Générales d&apos;Utilisation.
            </p>
            <p className="text-justify mb-4">
              L&apos;Éditeur se réserve le droit de modifier, à tout moment et sans préavis, le Site
              et des services ainsi que les présentes CGU, notamment pour s&apos;adapter aux
              évolutions du Site par la mise à disposition de nouvelles fonctionnalités ou la
              suppression ou la modification de fonctionnalités existantes.
            </p>
            <p className="text-justify mb-4">
              Il est donc conseillé à l&apos;Utilisateur de se référer avant toute navigation à la
              dernière version des Conditions Générales d&apos;Utilisation accessible à tout moment
              sur le Site.
            </p>
            <p className="text-justify mb-4">
              Les présentes Conditions Générales d&apos;Utilisation s&apos;appliquent, en tant que
              de besoin, à toute déclinaison ou extension du Site sur les réseaux sociaux et/ou
              communautaires existants ou à venir.
            </p>
            <div className="w-full mt-8">
              <span className="font-extrabold">Article 1er : Objet du site</span>
              <p className="text-justify mb-4">
                Le Site permet à l&apos;Utilisateur d&apos;accéder notamment aux services suivants :
                Prestation de services (le ou les «
                <span className="font-extrabold"> Service(s) </span>») .
              </p>
              <p className="text-justify mb-4">
                Durand, selon les présentes Conditions, accorde aux Utilisateurs un droit
                d&apos;accès limité révocable, non exclusif, non cessible aux Services à titre
                strictement personnel. Toute utilisation de la plate-forme contraire à sa finalité
                est strictement interdite et constitue un manquement aux présentes dispositions.
              </p>
            </div>
            <div className="w-full mt-8">
              <span className="font-extrabold">Article 2 : Accès au site</span>
              <p className="text-justify mb-4">
                Pour être éligible au Service, l&apos;Utilisateur peut être une personne physique ou
                morale.
              </p>
              <p className="text-justify mb-4">
                Durand se réserve le droit de suspendre ou de refuser un accès d&apos;un ou
                plusieurs Utilisateurs au Site.
              </p>
            </div>
            <div className="w-full mt-8">
              <span className="font-extrabold">Article 3 : Gestion du site</span>
              <p className="text-justify mb-4">
                L&apos;Editeur met en œuvre des solutions techniques nécessaires afin de permettre
                un accès continu au site pour l&apos;Utilisateur. Toutefois il se réserve la
                possibilité de limiter ou suspendre l&apos;accès à tout ou partie du Site à tout
                moment, et sans préavis. Une telle interruption pourra notamment être rendue
                nécessaire pour des raisons de maintenances du site ou de son contenu, ou pour toute
                autre raison jugée nécessaire au bon fonctionnement du site par l&apos;Editeur.
              </p>
              <p className="text-justify mb-4">
                L&apos;utilisation de la Plateforme requiert une connexion et un navigateur
                internet. La plateforme est accessible à l&apos;adresse suivante :
                https://nohit.studio.
              </p>
              <p className="text-justify mb-4">
                Tous matériels et logiciels nécessaires à l&apos;accès à la Plateforme et à
                l&apos;utilisation des Services restent exclusivement à la charge de
                l&apos;Utilisateur.
              </p>
            </div>
            <div className="w-full mt-8">
              <span className="font-extrabold">Article 4 : Contact</span>
              <p className="text-justify mb-4">
                L&apos;utilisateur a la possibilité de contacter l&apos;Editeur pour tout question
                ou demande d&apos;information concernant le Site, ou tout signalement de contenu ou
                d&apos;activités illicites par mail à l&apos;adresse{' '}
                <a className="text-coolerOrange font-bold" href="mailto:contact@nohit.studio">
                  contact@nohit.studio
                </a>
                .
              </p>
            </div>
            <div className="w-full mt-8">
              <span className="font-extrabold">
                Article 5 : Utilisation des services de la plateforme
              </span>
              <p className="text-justify mb-4">Les Utilisateurs s&apos;interdisent :</p>
              <ol className="list-disc">
                <li className="mb-4">
                  1. de transmettre, publier, distribuer, enregistrer ou détruire tout matériel, en
                  particulier les contenus de NO HIT Studio, en violation des lois ou
                  réglementations en vigueur concernant la collecte, le traitement ou le transfert
                  d&apos;informations personnelles ;
                </li>
                <li className="mb-4">
                  2. de diffuser des données, informations, ou contenus à caractère diffamatoire,
                  injurieux, obscène, offensant, violent ou incitant à la violence, ou à caractère
                  politique, raciste ou xénophobe et de manière générale tout contenu qui serait
                  contraire aux lois et règlements en vigueur ou aux bonnes mœurs ;
                </li>
                <li className="mb-4">
                  3. de référencer ou créer des liens vers tout contenu ou information disponible
                  depuis les sites de NO HIT Studio, sauf accord exprès, préalable et écrit de
                  Durand ;
                </li>
                <li className="mb-4">
                  4. d&apos;utiliser des informations, contenus ou toutes données présentes sur le
                  Site afin de proposer un service considéré comme concurrentiel à NO HIT Studio ;
                </li>
                <li className="mb-4">
                  5. de vendre, échanger ou monnayer des informations, contenus ou données présentes
                  sur la plateforme ou des Service proposés par la Plateforme, sans l&apos;accord
                  expresse et écrit de Durand ;
                </li>
                <li className="mb-4">
                  6. de pratiquer de l&apos;ingénierie inversée (Reverse Engineering), décompiler,
                  désassembler, déchiffrer ou autrement tenter d&apos;obtenir le code source en
                  relation avec toute propriété intellectuelle sous-jacente utilisée pour fournir
                  tout ou partie des Services ;
                </li>
                <li className="mb-4">
                  7. d&apos;utiliser des logiciels ou appareils manuels ou automates, robots de
                  codage ou autres moyens pour accéder, explorer, extraire ou indexer toute page du
                  Site ;
                </li>
                <li className="mb-4">
                  8. de mettre en danger ou essayer de mettre en danger la sécurité numérique de NO
                  HIT Studio. Cela comprend les tentatives de contrôler, scanner ou tester la
                  vulnérabilité du système ou réseau ou de violer des mesures de sécurité ou
                  d&apos;authentification sans une autorisation préalable expresse ;
                </li>
                <li className="mb-4">
                  9. de contrefaire ou d&apos;utiliser les produits, les logos, les marques ou tout
                  autre élément protégé par les droits de propriété intellectuel de Durand ;
                </li>
                <li className="mb-4">
                  10. de simuler l&apos;apparence ou le fonctionnement du Site, en procédant par
                  exemple à un effet miroir ;
                </li>
                <li className="mb-4">
                  11. de perturber ou troubler, directement ou indirectement NO HIT Studio, ou
                  imposer une charge disproportionnée sur l&apos;infrastructure du Site, ou de
                  tenter de transmettre ou d&apos;activer des virus informatiques via ou sur le Site
                  ;
                </li>
              </ol>
              Il est rappelé que les violations de la sécurité du système ou du réseau peuvent
              conduire à des poursuites civiles et pénales. Durand vérifie l&apos;absence de telle
              violation et peut faire appel aux autorités judiciaires pour poursuivre, le cas
              échéant, des Utilisateurs ayant participé à de telles violations. Les Utilisateurs
              s&apos;engagent à utiliser le Site de manière loyale, conformément à sa finalité et
              aux dispositions légales, réglementaires, aux présentes Conditions et aux usages en
              vigueur.
            </div>
            <div className="w-full mt-8">
              <span className="font-extrabold">Article 6 : Propriété intellectuelle</span>
              <p className="text-justify mb-4">
                L&apos;ensemble du contenu du Site, notamment les designs, textes, graphiques,
                images, vidéos, informations, logos, icônes-boutons, logiciels, fichiers audio et
                autres appartient à Durand, lequel est seul titulaire de l&apos;intégralité des
                droits de propriété intellectuelle y afférents.
              </p>
              <p className="text-justify mb-4">
                Toute représentation et/ou reproduction et/ou exploitation partielle ou totale des
                contenus et Services proposés par NO HIT Studio, par quelque procédé que ce soit,
                sans l&apos;autorisation préalable et écrite de Durand, est strictement interdite et
                serait susceptible de donner lieu à des poursuites judiciaires.
              </p>
            </div>
            <div className="w-full mt-8">
              <span className="font-extrabold">Article 7 : Données à caractère personnelles</span>
              <p className="text-justify mb-4">
                Toutes les données à caractère personnel dont dispose Durand sont recueillies
                légalement et loyalement selon les modalités de la politique de confidentialité
                accessible à cette adresse : nohit.studio/mentions.
              </p>
              <p className="text-justify mb-4">
                Ces données sont fournies par les Utilisateurs qui acceptent de manière volontaire
                et expresse les présentes Conditions autorisant Durand à traiter, divulguer ou
                transférer ces données à tout tiers cela afin de permettre (i) à l&apos;Utilisateur
                de profiter pleinement des Services et des fonctions proposés par le Site, (ii)
                prévenir toute fraude et /ou (iii) à des fins statistiques.
              </p>
              <p className="text-justify mb-4">
                Les données à caractère personnel sont stockées par Durand en vue de leur traitement
                dans le cadre de l&apos;utilisation des Services. Elles sont conservées aussi
                longtemps que nécessaire pour l&apos;apport des Services et fonctions offerts par le
                Site.
              </p>
              <p className="text-justify mb-4">
                L&apos;Utilisateur reste toujours propriétaire des informations le concernant
                qu&apos;il transmet à Durand. Il dispose, conformément à la loi n° 78-17 du 6
                janvier 1978 selon sa version consolidée au 24 mars 2020, d&apos;un droit
                d&apos;accès, de rectification et de suppression des données à caractère personnel
                le concernant, ainsi que du droit de s&apos;opposer à la communication de ces
                données à des tiers pour de justes motifs.
              </p>
              <p className="text-justify mb-4">
                L&apos;Utilisateur pourra exercer ses droits en écrivant à l&apos;adresse
                électronique suivante :{' '}
                <a className="text-coolerOrange font-bold" href="mailto:contact@nohit.studio">
                  contact@nohit.studio
                </a>
                .
              </p>
              <p className="text-justify mb-4">
                Une réponse à la requête de l&apos;Utilisateur lui sera adressée dans un délai de 30
                jours.
              </p>
            </div>
            <div className="w-full mt-8">
              <span className="font-extrabold">Article 8 : Responsabilité</span>
              <p className="text-justify mb-4">
                Il est rappelé que les données publiées par les Utilisateurs et les informations
                partagées par ces derniers peuvent être captées et exploitées par d&apos;autres
                Utilisateurs ou des tiers. En ce sens, Durand ne garantit pas le respect de la
                propriété de ces données, il incombe à l&apos;Utilisateur de prendre l&apos;ensemble
                des dispositions nécessaires afin que soit préservée la propriété de ses données.
              </p>
              <p className="text-justify mb-4">
                Durand ne garantit pas le fonctionnement sans interruption ou sans erreur de
                fonctionnement des Services, en particulier, la responsabilité de Durand ne saurait
                être engagée en cas d&apos;interruption d&apos;accès à la Plateforme en raison
                d&apos;opérations de maintenance, de mises à jour ou d&apos;améliorations
                techniques.
              </p>
              <p className="text-justify mb-4">
                En tout état de cause, Durand ne saurait en aucune circonstance être responsable au
                titre des pertes ou dommages indirects ou imprévisibles de l&apos;Utilisateur ou de
                tout tiers, ce qui inclut notamment tout gain manqué, tout investissement
                malheureux, inexactitude ou corruption de fichiers ou données, préjudice
                d&apos;image ou commercial, perte de chiffre d&apos;affaires ou bénéfice, perte de
                clientèle ou perte de chance lié à quelque titre et sur quelque fondement que ce
                soit.
              </p>
              <p className="text-justify mb-4">
                En outre, Durand ne saurait être responsable de tout retard ou inexécution du
                présent Contrat justifié par un cas de force majeure, telle qu&apos;elle est définie
                par la jurisprudence des cours et tribunaux français.
              </p>
            </div>
            <div className="w-full mt-8">
              <span className="font-extrabold">Article 9 : Convention de preuves</span>
              <p className="text-justify mb-4">
                Les systèmes et fichiers informatiques font foi dans les rapports entre Durand et
                l&apos;Utilisateur.
              </p>
              <p className="text-justify mb-4">
                Ainsi, Durand pourra valablement produire dans le cadre de toute procédure, aux fins
                de preuve les données, fichiers, programmes, enregistrements ou autres éléments,
                reçus, émis ou conservés au moyen des systèmes informatiques exploités, sur tous
                supports numériques ou analogiques, et s&apos;en prévaloir sauf erreur manifeste.
              </p>
            </div>
            <div className="w-full mt-8">
              <span className="font-extrabold">Article 10 : Indivisibilité</span>
              <p className="text-justify mb-4">
                Le fait que l&apos;une quelconque des dispositions du Contrat soit ou devienne
                illégale ou inapplicable n&apos;affectera en aucune façon la validité ou
                l&apos;applicabilité des autres stipulations du Contrat.
              </p>
            </div>
            <div className="w-full mt-8">
              <span className="font-extrabold">Article 11 : Règlement des différents</span>
              <p className="text-justify mb-4">
                La conclusion, l&apos;interprétation et la validité du présent Contrat sont régis
                par la loi française, quel que soit le pays d&apos;origine de l&apos;Utilisateur ou
                le pays depuis lequel l&apos;Utilisateur accède à NO HIT Studio et nonobstant les
                principes de conflits de lois.
              </p>
              <p className="text-justify mb-4">
                Dans l&apos;hypothèse où un différend portant sur la validité, l&apos;exécution ou
                l&apos;interprétation du présent Contrat et serait porté devant les juridictions
                civiles, il sera soumis à la compétence exclusive des tribunaux français auquel il
                est fait expressément attribution de compétence, même en cas de référé ou de
                pluralité de défendeurs.
              </p>
              <p className="text-justify mb-4">
                L&apos;Utilisateur est informé qu&apos;il peut en tout état de cause recourir à une
                médiation conventionnelle ou à tout mode alternatif de règlement des différends
                (conciliation par exemple) en cas de contestation.
              </p>
            </div>
            <div className="w-full mt-8">
              <span className="font-extrabold">
                Article 12 : Durée des conditions générales d&apos;utilisations
              </span>
              <p className="text-justify mb-4">
                Les présentes conditions générales d&apos;utilisation sont conclues pour une durée
                indéterminée, l&apos;Utilisateur est tenu de les respecter à compter du début de son
                utilisation des Services.
              </p>
              <p className="text-justify mb-4">
                L&apos;Editeur se réserve la possibilité de modifier ce document à tout moment et
                sans préavis. Les utilisateurs seront informés de chaque mise à jour du document.
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col text-lg">
            <div className="w-full flex flex-col items-center mb-8">
              <span className="text-center font-extrabold">Terms and conditions</span>
              <span className="text-center italic">Last update: 05/07/2022</span>
            </div>
            <div className="w-full mt-8">
              <h2 className="font-extrabold">Privacy Policy</h2>
              <p className="text-justify mb-4">
                Your privacy is important to us. It is NO HIT Studio&#39;s policy to respect your
                privacy and comply with any applicable law and regulation regarding any personal
                information we may collect about you, including across our website,{' '}
                <a href="https://nohit.studio" className="text-coolerOrange font-bold">
                  https://nohit.studio
                </a>
                , and other sites we own and operate.{' '}
              </p>
            </div>
            <div className="w-full mt-8">
              <h3 className="font-extrabold">Information We Collect</h3>
              <p className="text-justify mb-4">
                Information we collect includes both information you knowingly and actively provide
                us when using or participating in any of our services and promotions, and any
                information automatically sent by your devices in the course of accessing our
                products and services.{' '}
              </p>
            </div>
            <div className="w-full mt-8">
              <h4 className="font-extrabold">Log Data</h4>
              <p className="text-justify mb-4">
                When you visit our website, our servers may automatically log the standard data
                provided by your web browser. It may include your device’s Internet Protocol (IP)
                address, your browser type and version, the pages you visit, the time and date of
                your visit, the time spent on each page, other details about your visit, and
                technical details that occur in conjunction with any errors you may encounter.{' '}
              </p>
              <p className="text-justify mb-4">
                Please be aware that while this information may not be personally identifying by
                itself, it may be possible to combine it with other data to personally identify
                individual persons.{' '}
              </p>
            </div>
            <div className="w-full mt-8">
              <h4 className="font-extrabold">Personal Information</h4>
              <p className="text-justify mb-4">
                We may ask for personal information which may include one or more of the following:{' '}
              </p>
              <ul className="list-disc">
                <li className="mb-4">Name</li>
                <li className="mb-4">Email</li>
                <li className="mb-4">Phone/mobile number</li>
              </ul>
            </div>
            <div className="w-full mt-8">
              <h4 className="font-extrabold">
                Legitimate Reasons for Processing Your Personal Information
              </h4>
              <p className="text-justify mb-4">
                We only collect and use your personal information when we have a legitimate reason
                for doing so. In which instance, we only collect personal information that is
                reasonably necessary to provide our services to you.{' '}
              </p>
            </div>
            <div className="w-full mt-8">
              <h4 className="font-extrabold">Collection and Use of Information</h4>
              <p className="text-justify mb-4">
                We may collect personal information from you when you do any of the following on our
                website:{' '}
              </p>
              <ul className="list-disc">
                <li className="mb-4">Use a mobile device or web browser to access our content</li>
                <li className="mb-4">
                  Contact us via email, social media, or on any similar technologies
                </li>
                <li className="mb-4">When you mention us on social media</li>
              </ul>
              <p className="text-justify mb-4">
                We may collect, hold, use, and disclose information for the following purposes, and
                personal information will not be further processed in a manner that is incompatible
                with these purposes:{' '}
              </p>
              <p className="text-justify mb-4">
                We may collect, hold, use, and disclose information for the following purposes, and
                personal information will not be further processed in a manner that is incompatible
                with these purposes:{' '}
              </p>
              <ul className="list-disc">
                <li className="mb-4">to contact and communicate with you</li>
              </ul>
              <p className="text-justify mb-4">
                Please be aware that we may combine information we collect about you with general
                information or research data we receive from other trusted sources.{' '}
              </p>
            </div>
            <div className="w-full mt-8">
              <h4 className="font-extrabold">Security of Your Personal Information</h4>
              <p className="text-justify mb-4">
                When we collect and process personal information, and while we retain this
                information, we will protect it within commercially acceptable means to prevent loss
                and theft, as well as unauthorized access, disclosure, copying, use, or
                modification.{' '}
              </p>
              <p className="text-justify mb-4">
                Although we will do our best to protect the personal information you provide to us,
                we advise that no method of electronic transmission or storage is 100% secure, and
                no one can guarantee absolute data security. We will comply with laws applicable to
                us in respect of any data breach.{' '}
              </p>
              <p className="text-justify mb-4">
                You are responsible for selecting any password and its overall security strength,
                ensuring the security of your own information within the bounds of our services.{' '}
              </p>
            </div>
            <div className="w-full mt-8">
              <h4 className="font-extrabold">How Long We Keep Your Personal Information</h4>
              <p className="text-justify mb-4">
                We keep your personal information only for as long as we need to. This time period
                may depend on what we are using your information for, in accordance with this
                privacy policy. If your personal information is no longer required, we will delete
                it or make it anonymous by removing all details that identify you.{' '}
              </p>
              <p className="text-justify mb-4">
                However, if necessary, we may retain your personal information for our compliance
                with a legal, accounting, or reporting obligation or for archiving purposes in the
                public interest, scientific, or historical research purposes or statistical
                purposes.{' '}
              </p>
            </div>
            <div className="w-full mt-8">
              <h3 className="font-extrabold">Children’s Privacy</h3>
              <p className="text-justify mb-4">
                We do not aim any of our products or services directly at children under the age of
                13, and we do not knowingly collect personal information about children under 13.{' '}
              </p>
            </div>
            <div className="w-full mt-8">
              <h3 className="font-extrabold">
                Disclosure of Personal Information to Third Parties
              </h3>
              <p className="text-justify mb-4">We may disclose personal information to: </p>
              <ul>
                <li className="mb-4">a parent, subsidiary, or affiliate of our company</li>
                <li className="mb-4">
                  third party service providers for the purpose of enabling them to provide their
                  services, for example, IT service providers, data storage, hosting and server
                  providers, advertisers, or analytics platforms
                </li>
                <li className="mb-4">our employees, contractors, and/or related entities</li>
                <li className="mb-4">our existing or potential agents or business partners</li>
                <li className="mb-4">
                  sponsors or promoters of any competition, sweepstakes, or promotion we run
                </li>
                <li className="mb-4">
                  courts, tribunals, regulatory authorities, and law enforcement officers, as
                  required by law, in connection with any actual or prospective legal proceedings,
                  or in order to establish, exercise, or defend our legal rights
                </li>
                <li className="mb-4">
                  third parties, including agents or sub-contractors, who assist us in providing
                  information, products, services, or direct marketing to you third parties to
                  collect and process data
                </li>
              </ul>
            </div>
            <div className="w-full mt-8">
              <h3 className="font-extrabold">International Transfers of Personal Information</h3>
              <p className="text-justify mb-4">
                The personal information we collect is stored and/or processed where we or our
                partners, affiliates, and third-party providers maintain facilities. Please be aware
                that the locations to which we store, process, or transfer your personal information
                may not have the same data protection laws as the country in which you initially
                provided the information. If we transfer your personal information to third parties
                in other countries: (i) we will perform those transfers in accordance with the
                requirements of applicable law; and (ii) we will protect the transferred personal
                information in accordance with this privacy policy.{' '}
              </p>
            </div>
            <div className="w-full mt-8">
              <h3 className="font-extrabold">
                Your Rights and Controlling Your Personal Information
              </h3>
              <p className="text-justify mb-4">
                You always retain the right to withhold personal information from us, with the
                understanding that your experience of our website may be affected. We will not
                discriminate against you for exercising any of your rights over your personal
                information. If you do provide us with personal information you understand that we
                will collect, hold, use and disclose it in accordance with this privacy policy. You
                retain the right to request details of any personal information we hold about you.{' '}
              </p>
              <p className="text-justify mb-4">
                If we receive personal information about you from a third party, we will protect it
                as set out in this privacy policy. If you are a third party providing personal
                information about somebody else, you represent and warrant that you have such
                person’s consent to provide the personal information to us.{' '}
              </p>
              <p className="text-justify mb-4">
                If you have previously agreed to us using your personal information for direct
                marketing purposes, you may change your mind at any time. We will provide you with
                the ability to unsubscribe from our email-database or opt out of communications.
                Please be aware we may need to request specific information from you to help us
                confirm your identity.{' '}
              </p>
              <p className="text-justify mb-4">
                If you believe that any information we hold about you is inaccurate, out of date,
                incomplete, irrelevant, or misleading, please contact us using the details provided
                in this privacy policy. We will take reasonable steps to correct any information
                found to be inaccurate, incomplete, misleading, or out of date.{' '}
              </p>
              <p className="text-justify mb-4">
                If you believe that we have breached a relevant data protection law and wish to make
                a complaint, please contact us using the details below and provide us with full
                details of the alleged breach. We will promptly investigate your complaint and
                respond to you, in writing, setting out the outcome of our investigation and the
                steps we will take to deal with your complaint. You also have the right to contact a
                regulatory body or data protection authority in relation to your complaint.{' '}
              </p>
            </div>
            <div className="w-full mt-8">
              <h3 className="font-extrabold">Use of Cookies</h3>
              <p className="text-justify mb-4">
                We use &ldquo;cookies&rdquo; to collect information about you and your activity
                across our site. A cookie is a small piece of data that our website stores on your
                computer, and accesses each time you visit, so we can understand how you use our
                site. This helps us serve you content based on preferences you have specified.{' '}
              </p>
            </div>
            <div className="w-full mt-8">
              <h3 className="font-extrabold">Limits of Our Policy</h3>
              <p className="text-justify mb-4">
                Our website may link to external sites that are not operated by us. Please be aware
                that we have no control over the content and policies of those sites, and cannot
                accept responsibility or liability for their respective privacy practices.{' '}
              </p>
            </div>
            <div className="w-full mt-8">
              <h3 className="font-extrabold">Changes to This Policy</h3>
              <p className="text-justify mb-4">
                At our discretion, we may change our privacy policy to reflect updates to our
                business processes, current acceptable practices, or legislative or regulatory
                changes. If we decide to change this privacy policy, we will post the changes here
                at the same link by which you are accessing this privacy policy.{' '}
              </p>
              <p className="text-justify mb-4">
                If required by law, we will get your permission or give you the opportunity to opt
                in to or opt out of, as applicable, any new uses of your personal information.{' '}
              </p>
            </div>
            <div className="w-full mt-8">
              <h3 className="font-extrabold">Contact Us</h3>
              <p className="text-justify mb-4">
                For any questions or concerns regarding your privacy, you may contact us using the
                following details:{' '}
              </p>
              <p>
                Bruno DURAND
                <br />
                <a className="text-coolerOrange font-bold" href="mailto:contact@nohit.studio">
                  contact@nohit.studio
                </a>
              </p>
            </div>
          </div>
        )}
      </div>
      {/* eslint-enable */}
    </div>
  );
};

export default Terms;
