import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ProjectDetailContent from '../../components/UI/ProjectDetailContent';
import NotFound from '../NotFound';
import graphismCards from '../Home/graphismCards';
import graphismCardsEn from '../Home/graphismCardsEn';
import letteringCards from '../Home/letteringCards';
import letteringCardsEn from '../Home/letteringCardsEn';

function Project() {
  const { type, id } = useParams();
  const [content, setContent] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    let findContent;
    switch (type) {
      case 'graphism':
        if (i18next.language === 'en') findContent = graphismCardsEn.find((x) => x.id === id);
        else findContent = graphismCards.find((x) => x.id === id);
        break;

      case 'lettering':
        if (i18next.language === 'en') findContent = letteringCardsEn.find((x) => x.id === id);
        else findContent = letteringCards.find((x) => x.id === id);
        break;

      default:
        break;
    }
    if (findContent) {
      setContent(findContent);
    }
  }, [id, type, t]);
  return (
    <>
      {content ? (
        <div className="h-full w-ful">
          <ProjectDetailContent content={content} />
        </div>
      ) : <NotFound />}
    </>

  );
}

export default Project;
