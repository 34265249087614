import React from 'react';

const graphismCardsEn = [
  {
    title: 'Aktis Lab',
    type: 'Identity creation',
    subtitle: 'Identity creation for Aktis Lab.',
    imgAlt: 'This is an alt',
    imgSrc: '/images/design/aktis.webp',
    imgFullSrc: '/images/design/aktis.webp',
    imgCredits: 'Crédits: ©AktisLab',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <div className="text-base max-w-prose mx-auto lg:max-w-none">
          <p className="text-lg dark:text-white text-black ">
            Bucaneers are the shipmates of the big madness. Desire me grog, ye heavy-hearted pin!
            The shark marks with horror, view the galley.
          </p>
        </div>
        <div className="mt-5 prose prose-indigo dark:text-white text-black mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
          <p>
            Divided, melted pudding is best flavored with salted hollandaise sauce. With blueberries
            drink peppermint tea. Blood oranges can be tossed with raw pumpkin seeds, also try
            seasoning the platter with bourbon.
          </p>

          <p className="mt-5 mb-5">
            This anomaly has only been yearned by a spheroid sun. Why does the sonic shower harvest?
            Sensors meet with stigma at the fantastic habitat accelerativemake it so! Creature of a
            harmless starlight travel, discover the sensor.
          </p>
          <p className="mt-5 mb-5">
            Dictum magnis risus phasellus vitae quam morbi. Quis lorem lorem arcu, metus, egestas
            netus cursus. In.
          </p>
          <p className="mt-5 mb-5">
            Rhoncus nisl, libero egestas diam fermentum dui. At quis tincidunt vel ultricies.
            Vulputate aliquet velit faucibus semper. Pellentesque in venenatis vestibulum
            consectetur nibh id. In id ut tempus egestas. Enim sit aliquam nec, a. Morbi enim
            fermentum lacus in. Viverra.
          </p>
          <p className="mt-5 mb-5">
            To the instant shrimps add chicken lard, melon, vinegar and chilled ground beef. With
            spinach drink plain vinegar. Try roasting pork shoulder soup garnished with gold
            tequila. Try sliceing nachos ricotta mixed with rice vinegar. Try enameling the red wine
            mackerels with aged anchovy essence and triple sec, roasted.
          </p>

          <h3>How we helped</h3>
          <p>
            Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in
            volutpat, diam. Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet
            sem est phasellus eu proin massa, lectus. Diam rutrum posuere donec ultricies non morbi.
            Mi a platea auctor mi.
          </p>
          <p>
            Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur
            neque tristique pellentesque. Blandit amet, sed aenean erat arcu morbi.
          </p>
        </div>
      </div>
    ),
  },
  {
    title: 'Vincipit',
    type: 'Identity creation',
    subtitle: 'Identity creation for Vincipit.',
    imgAlt: 'This is an alt',
    imgSrc: '/images/design/vivi.webp',
    imgFullSrc: '/images/design/vivi.webp',
    imgCredits: 'Crédits: ©Vincipit',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <div className="text-base max-w-prose mx-auto lg:max-w-none">
          <p className="text-lg dark:text-white text-black ">
            Bucaneers are the shipmates of the big madness. Desire me grog, ye heavy-hearted pin!
            The shark marks with horror, view the galley.
          </p>
        </div>
        <div className="mt-5 prose prose-indigo dark:text-white text-black mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
          <p>
            Divided, melted pudding is best flavored with salted hollandaise sauce. With blueberries
            drink peppermint tea. Blood oranges can be tossed with raw pumpkin seeds, also try
            seasoning the platter with bourbon.
          </p>

          <p className="mt-5 mb-5">
            This anomaly has only been yearned by a spheroid sun. Why does the sonic shower harvest?
            Sensors meet with stigma at the fantastic habitat accelerativemake it so! Creature of a
            harmless starlight travel, discover the sensor.
          </p>
          <p className="mt-5 mb-5">
            Dictum magnis risus phasellus vitae quam morbi. Quis lorem lorem arcu, metus, egestas
            netus cursus. In.
          </p>
          <p className="mt-5 mb-5">
            Rhoncus nisl, libero egestas diam fermentum dui. At quis tincidunt vel ultricies.
            Vulputate aliquet velit faucibus semper. Pellentesque in venenatis vestibulum
            consectetur nibh id. In id ut tempus egestas. Enim sit aliquam nec, a. Morbi enim
            fermentum lacus in. Viverra.
          </p>
          <p className="mt-5 mb-5">
            To the instant shrimps add chicken lard, melon, vinegar and chilled ground beef. With
            spinach drink plain vinegar. Try roasting pork shoulder soup garnished with gold
            tequila. Try sliceing nachos ricotta mixed with rice vinegar. Try enameling the red wine
            mackerels with aged anchovy essence and triple sec, roasted.
          </p>

          <h3>How we helped</h3>
          <p>
            Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in
            volutpat, diam. Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet
            sem est phasellus eu proin massa, lectus. Diam rutrum posuere donec ultricies non morbi.
            Mi a platea auctor mi.
          </p>
          <p>
            Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur
            neque tristique pellentesque. Blandit amet, sed aenean erat arcu morbi.
          </p>
        </div>
      </div>
    ),
  },
  {
    title: 'JCR',
    type: 'Identity creation',
    subtitle: 'Identity creation for JCR Patrimoine.',
    imgAlt: 'This is an alt',
    imgSrc: '/images/design/jcr.webp',
    imgFullSrc: '/images/design/jcr.webp',
    imgCredits: 'Crédits: ©JCR Patrimoine',
    subContent: (
      <div className="mt-8 lg:mt-0 ">
        <div className="text-base max-w-prose mx-auto lg:max-w-none">
          <p className="text-lg dark:text-white text-black ">
            Bucaneers are the shipmates of the big madness. Desire me grog, ye heavy-hearted pin!
            The shark marks with horror, view the galley.
          </p>
        </div>
        <div className="mt-5 prose prose-indigo dark:text-white text-black mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
          <p>
            Divided, melted pudding is best flavored with salted hollandaise sauce. With blueberries
            drink peppermint tea. Blood oranges can be tossed with raw pumpkin seeds, also try
            seasoning the platter with bourbon.
          </p>

          <p className="mt-5 mb-5">
            This anomaly has only been yearned by a spheroid sun. Why does the sonic shower harvest?
            Sensors meet with stigma at the fantastic habitat accelerativemake it so! Creature of a
            harmless starlight travel, discover the sensor.
          </p>
          <p className="mt-5 mb-5">
            Dictum magnis risus phasellus vitae quam morbi. Quis lorem lorem arcu, metus, egestas
            netus cursus. In.
          </p>
          <p className="mt-5 mb-5">
            Rhoncus nisl, libero egestas diam fermentum dui. At quis tincidunt vel ultricies.
            Vulputate aliquet velit faucibus semper. Pellentesque in venenatis vestibulum
            consectetur nibh id. In id ut tempus egestas. Enim sit aliquam nec, a. Morbi enim
            fermentum lacus in. Viverra.
          </p>
          <p className="mt-5 mb-5">
            To the instant shrimps add chicken lard, melon, vinegar and chilled ground beef. With
            spinach drink plain vinegar. Try roasting pork shoulder soup garnished with gold
            tequila. Try sliceing nachos ricotta mixed with rice vinegar. Try enameling the red wine
            mackerels with aged anchovy essence and triple sec, roasted.
          </p>

          <h3>How we helped</h3>
          <p>
            Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in
            volutpat, diam. Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet
            sem est phasellus eu proin massa, lectus. Diam rutrum posuere donec ultricies non morbi.
            Mi a platea auctor mi.
          </p>
          <p>
            Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur
            neque tristique pellentesque. Blandit amet, sed aenean erat arcu morbi.
          </p>
        </div>
      </div>
    ),
  },
];

export default graphismCardsEn;
