import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ThemeContext from './contexts/themeContext';
import Page from './components/Page';
import Router from './Router';
import Header from './components/Header';
import Footer from './components/Footer';

const App = () => {
  const [theme, setTheme] = useState(localStorage.getItem('NHS-theme') || 'light');
  return (
    <div
      className={'w-full h-full flex flex-col pt-4 px-4 md:pt-8 md:px-10 md:pb-5 md:pr-0 xl:pl-20 xl:py-20 overflow-hidden transition duration-500 font-sans '.concat(
        theme === 'dark' ? 'bg-black text-white dark' : 'bg-white text-black',
      )}
    >
      <BrowserRouter>
        <ThemeContext.Provider
          value={{
            theme,
            changeTheme: () => {
              const newTheme = theme === 'dark' ? 'light' : 'dark';
              setTheme(newTheme);
              localStorage.setItem('NHS-theme', newTheme);
            },
          }}
        >
          <Header />
          <Page>
            <Router />
          </Page>
          <Footer />
        </ThemeContext.Provider>
      </BrowserRouter>
    </div>
  );
};

export default App;
