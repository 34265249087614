import propTypes from 'prop-types';
import React from 'react';

const Aktis = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 646.79 131.83">
    <linearGradient id="Dégradé_sans_nom_532" x1="523.69" y1="140.69" x2="644.63" y2="19.74" gradientUnits="userSpaceOnUse">
      <stop offset="0.13" stopColor="#fccb00" />
      <stop offset="1" stopColor="#e5007a" />
    </linearGradient>
    <g id="Calque_2" data-name="Calque 2">
      <path className={className} d="M428.63,40.34c0-4.32,4-7.92,16.39-7.92,9.5,0,19.53,2.28,30.1,7a5.39,5.39,0,0,0,7.19-2.84L491,15.6a5.38,5.38,0,0,0-2.64-6.95C475.89,2.84,460.42,0,445.38,0c-29.66,0-48.11,11-55.28,26.55V8.28a5.4,5.4,0,0,0-5.4-5.4H353a5.38,5.38,0,0,0-1.89.36,5.37,5.37,0,0,0-1.88-.36H223.58a5.39,5.39,0,0,0-4,1.78L189.16,38.25a5.4,5.4,0,0,1-9.4-3.63V8.28a5.4,5.4,0,0,0-5.4-5.4h-31a5.4,5.4,0,0,0-5.4,5.4v95.11L95.46,6.12a5.4,5.4,0,0,0-4.95-3.24H55.79a5.39,5.39,0,0,0-4.94,3.24L.46,121.39A5.4,5.4,0,0,0,5.4,129H36.67a5.42,5.42,0,0,0,5-3.46l5.8-15.06a5.4,5.4,0,0,1,5-3.46H93a5.42,5.42,0,0,1,5,3.46l5.8,15.06a5.4,5.4,0,0,0,5,3.46h32a5.45,5.45,0,0,0,1.23-.16,5.7,5.7,0,0,0,1.25.16h31a5.4,5.4,0,0,0,5.4-5.4V99.86a5.4,5.4,0,0,1,1.38-3.61L184.93,92a5.38,5.38,0,0,1,8.32.36l25.95,34.4a5.41,5.41,0,0,0,4.31,2.15h35.68a5.4,5.4,0,0,0,4.32-8.64L219.72,61.92a5.39,5.39,0,0,1,.32-6.87l19.39-21.33a5.36,5.36,0,0,0,4.25,2.12h26.13a5.4,5.4,0,0,1,5.4,5.4v82.31a5.39,5.39,0,0,0,5.39,5.4h31.71a5.4,5.4,0,0,0,5.4-5.4V41.24a5.4,5.4,0,0,1,5.4-5.4H347.6v87.71A5.4,5.4,0,0,0,353,129h31.7a5.4,5.4,0,0,0,5.4-5.4v-1.78c12.79,6.11,31.22,10.06,49.52,10.06,39.08,0,58.71-19.45,58.71-42.5C498.33,42.86,428.63,55.47,428.63,40.34Zm-351.46,36H68.42a5.4,5.4,0,0,1-5-7.34l4.38-11.33a5.4,5.4,0,0,1,10.07,0L82.2,69A5.39,5.39,0,0,1,77.17,76.36ZM440,99.41a86.74,86.74,0,0,1-36.7-8.88A5.36,5.36,0,0,0,396,93.22l-5.92,13.4V58.38c14,28.21,66.27,20.46,66.27,34.19C456.37,96.89,452.05,99.41,440,99.41Z" />
      <path className="fill-[url(#Dégradé_sans_nom_532)]" d="M645.65,111.57l-4.43-10.14L621.76,56.91a13.43,13.43,0,0,1,0-10.76l11.32-25.9a13.43,13.43,0,0,0-12.31-18.8h-57.2A13.44,13.44,0,0,0,551.26,9.5L506.64,111.57A13.43,13.43,0,0,0,519,130.38h114.4A13.43,13.43,0,0,0,645.65,111.57Zm-59.07-10.14H565A7.72,7.72,0,0,1,557.77,91l5.49-14.35,5.31-13.77a7.73,7.73,0,0,1,14.42,0L588.3,76.6,593.79,91A7.72,7.72,0,0,1,586.58,101.43Z" />
    </g>
  </svg>
);

Aktis.propTypes = {
  className: propTypes.string,
};

Aktis.defaultProps = {
  className: '',
};

export default Aktis;
