import React from 'react';
import * as propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Back from '../../images/Back';

function ProjectDetailContent({ content }) {
  return (
    <div
      className="bg-white dark:bg-black transition duration-500 h-full overflow-y-scroll overflow-x-hidden md:mr-4 scrollbar
      scrollbar-thumb-rounded-full
      scrollbar-track-transparent
      scrollbar-w-0
      md:scrollbar-w-2"
    >
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Link to="/" className="top-6 sm:top-0 left-0">
          <Back className="w-8 fill-black dark:fill-white transition duration-500" />
        </Link>
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="mt-2 text-base text-coolerOrange font-semibold tracking-wide uppercase">
              {content.type}
            </h2>
            <h3 className="text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl">
              {content.title}
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-[70%_30%] lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="#ff7510"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg shadow-lg object-cover object-center"
                    src={content.imgFullSrc}
                    alt={content.imgAlt}
                    width={1184}
                    height={1376}
                  />
                </div>
                <figcaption className="mt-3 flex text-sm dark:text-white text-black ">
                  <span className="ml-2">{content.imgCredits}</span>
                </figcaption>
              </figure>
            </div>
          </div>
          {content.subContent}
        </div>
      </div>
    </div>
  );
}

export default ProjectDetailContent;

ProjectDetailContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  content: propTypes.any.isRequired,
};
