import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home/Home';
import Terms from './pages/Terms';
import NotFound from './pages/NotFound';
import Project from './pages/Project/[id]';

const Router = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/terms" exact component={Terms} />
    <Route path="/projects/:type/:id" component={Project} />
    <Route path="*" component={NotFound} />
  </Switch>
);

export default Router;
