import propTypes from 'prop-types';
import React from 'react';

const Maho = ({
  className,
}) => (
  <svg className={className} viewBox="0 0 224.15 57.87" xmlns="http://www.w3.org/2000/svg">
    <g id="c">
      <g>
        <polygon className={className} points="25.16 37.64 50.3 3.14 50.3 55.7 39.98 55.7 39.98 34.42 25.16 54.87 10.32 34.42 10.32 55.7 0 55.7 0 3.14 25.16 37.64" />
        <path className="d" d="M91.06,38.79l-4.68-10.86-4.75,10.86h9.43ZM86.35,3.3l23.44,52.4h-11.18l-3.47-7.8h-17.59l-3.47,7.8h-11.15L86.35,3.3Z" />
        <polygon className="d" points="132.71 4.35 132.71 24.9 149.88 24.9 149.88 4.35 160.17 4.35 160.17 55.7 149.88 55.7 149.88 35.12 132.71 35.12 132.71 55.7 122.42 55.7 122.42 4.35 132.71 4.35" />
        <polygon className="d" points="211.02 0 188.16 0 187.74 0 187.33 0 187.33 .41 187.33 .82 187.33 3.39 187.33 3.8 187.33 4.21 187.74 4.21 188.16 4.21 211.02 4.21 211.44 4.21 211.44 3.39 211.44 .41 211.44 0 211.02 0" />
        <path className="fill-[#ff3333]" d="M224.15,32.83c0,13.83-11.21,25.04-25.04,25.04s-25.04-11.21-25.04-25.04,11.21-25.04,25.04-25.04,25.04,11.21,25.04,25.04" />
      </g>
    </g>
  </svg>

);

Maho.propTypes = {
  className: propTypes.string,
};

Maho.defaultProps = {
  className: '',
};

export default Maho;
